import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { PermissionedRoute } from './components/PermissionedRoute'
import { InternalLayout } from './components/layouts/InternalLayout'
import { ExternalLayout } from './components/layouts/ExternalLayout'
import { ERoutePermissions } from '../enums/ERoutePermissions'
import { ResetPasswordForm } from '../screens/resetPassword/ResetPasswordForm'
import { Login } from '../screens/login/Login'
import { ChangePassword } from '../screens/changePassword/ChangePassword'
import { Breadcrumb } from '../components/breadcrumb/Breadcrumb'
import { displayMessage } from '../helpers/messages'
import { ListClients } from '../screens/client/ListClients'
import { ListEvents } from '../screens/event/ListEvents'
import { ClientDetail } from '../screens/client/components/ClientDetail'
import UserDetail from '../screens/user/components/UserDetail'
import { ListUsers } from '../screens/user/ListUsers'
import PersonDetail from '../screens/client/components/PersonDetail'
import { DetailProfile } from '../screens/user/components/DetailProfile'
import { ListUserProgenos } from '../screens/userProgenos/ListUserProgenos'
import { ListMedicalExams } from '../screens/exams/ListExams'
import ExamsDetail from '../screens/exams/components/ExamsDetail'

const router = createBrowserRouter([
    //Rotas privadas
    {
        path: '/',
        element: <PrivateRoute />,
        children: [
            {
                path: '/',
                element: <InternalLayout />,
                children: [
                    {
                        path: 'clients',
                        handle: { crumb: { title: displayMessage('CLIENTS') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <Breadcrumb>
                                        <ListClients></ListClients>
                                    </Breadcrumb>
                                ),
                            },
                            {
                                path: ':clientId',
                                handle: {
                                    crumb: {
                                        title: displayMessage('DETAIL_CLIENT'),
                                    },
                                },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <ClientDetail></ClientDetail>
                                            </Breadcrumb>
                                        ),
                                    },
                                    {
                                        path: 'people/:personId',
                                        handle: {
                                            crumb: {
                                                title: displayMessage('USER_DETAIL'),
                                            },
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <Breadcrumb>
                                                        <PersonDetail></PersonDetail>
                                                    </Breadcrumb>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'events',
                        handle: { crumb: { title: displayMessage('LIST_EVENT') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <Breadcrumb>
                                        <ListEvents></ListEvents>
                                    </Breadcrumb>
                                ),
                            },
                        ],
                    },
                    {
                        path: 'users-progenos',
                        handle: { crumb: { title: displayMessage('LIST_USER') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <Breadcrumb>
                                        <ListUserProgenos></ListUserProgenos>
                                    </Breadcrumb>
                                ),
                            },
                        ],
                    },
                    {
                        path: 'exams',
                        handle: { crumb: { title: displayMessage('EXAMS') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <Breadcrumb>
                                        <ListMedicalExams></ListMedicalExams>
                                    </Breadcrumb>
                                ),
                            },
                            {
                                path: ':examsId',
                                handle: {
                                    crumb: {
                                        title: displayMessage('EXAMS_DETAIL'),
                                    },
                                },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <ExamsDetail></ExamsDetail>
                                            </Breadcrumb>
                                        ),
                                    },
                                ], 
                            }
                        ],
                    },
                    {
                        path: 'profile',
                        handle: { crumb: { title: displayMessage('MY_PROFILE') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <Breadcrumb>
                                        <DetailProfile></DetailProfile>
                                    </Breadcrumb>
                                ),
                            },
                        ],
                    },
                    {
                        path: 'users',
                        handle: { crumb: { title: displayMessage('LIST_USER') } },
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionedRoute routePermissions={[ERoutePermissions.ADMIN]}>
                                        <Breadcrumb>
                                            <ListUsers></ListUsers>
                                        </Breadcrumb>
                                    </PermissionedRoute>
                                ),
                            },
                            {
                                path: ':userId',
                                handle: {
                                    crumb: {
                                        title: displayMessage('USER_DETAIL'),
                                    },
                                },
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <Breadcrumb>
                                                <UserDetail></UserDetail>
                                            </Breadcrumb>
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'change-password',
                element: (
                    <ExternalLayout>
                        <ChangePassword />
                    </ExternalLayout>
                ),
            },
        ],
    },

    //Rotas públicas
    {
        path: 'login',
        element: <ExternalLayout />,
        children: [
            {
                index: true,
                element: <Login />,
            },
            {
                path: 'reset-password/:token',
                element: <ResetPasswordForm />,
            },
        ],
    },
])

export function Router() {
    return <RouterProvider router={router} />
}
