import React, { useState, useContext, useEffect } from 'react'
import { Input, Modal, Form, Select, Row, Col, Button, Divider, Switch, Popover } from 'antd'
import api from '../../../services/api'
import { SL_Button } from '../../../components/buttons/Button'
import { displayMessage } from '../../../helpers/messages'
import { formatFullTimeDate } from '../../../helpers/date'

const BODY_EXAMPLE = ` "data": {
    "registry": {
        ...data
    },
    "progenos": {
        ...data
    }
}`

const HEADER_EXAMPLE = '{ "x-secret": "seu token"  }'

export function DetailAccessKeyModal({ accessKeyData, onClose, onLoadClientAccessKeys }) {
    const [name, setName] = useState(accessKeyData?.name ?? '')
    const [clientUrlApi, setClientUrlApi] = useState(accessKeyData?.client_url_api ?? '')
    const [formatToXML, setFormatToXML] = useState(accessKeyData?.is_xml_body_format ?? false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const isKeyActive = accessKeyData?.is_active

    async function onCloseModal() {
        if (isLoading) return

        onClose()
    }

    function onChangeName(e) {
        setName(e.target.value)
    }

    function onChangeClientUrlApi(e) {
        if (e.target.value == '') {
            setClientUrlApi(null)
            return
        }

        setClientUrlApi(e.target.value)
    }

    async function onUpdateClientAccessKey() {
        if (isLoading) return

        try {
            setIsLoading(true)

            const createBody = { name, client_url_api: clientUrlApi, is_xml_body_format: formatToXML }
            await api.clientAccessKeys.update(createBody, accessKeyData.id)

            await onLoadClientAccessKeys(true)
        } catch (error) {
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }
    }

    async function onChangeEdit() {
        if (isEditing) {
            await onUpdateClientAccessKey()
        }

        setIsEditing((current) => !current)
    }

    function onChangeName(e) {
        setName(e.target.value)
    }

    async function onChangeAccessKeyStatus(inactivate = true) {
        if (isLoading) return

        try {
            setIsLoading(true)

            if (inactivate) {
                await api.clientAccessKeys.inactivate(accessKeyData.id)
            } else {
                await api.clientAccessKeys.activate(accessKeyData.id)
            }

            await onLoadClientAccessKeys(true)
        } catch (error) {
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }
    }

    return (
        <Modal
            title={<div className="text-xl font-bold text-fit-green-900 mr-4 text-primary-900">{displayMessage('DETAIL_ACCESS_KEY')}</div>}
            open={true}
            onCancel={onCloseModal}
            centered={true}
            footer={
                <div className="flex justify-end flex-col-reverse ss:flex-row gap-2">
                    <SL_Button className="ss:w-[150px] bg-gray-500 border-transparent" onClick={onCloseModal}>
                        {displayMessage('CLOSE')}
                    </SL_Button>

                    {isKeyActive ? (
                        <SL_Button danger={true} disabled={isLoading} className="ss:w-[150px] m-0" onClick={() => onChangeAccessKeyStatus(true)}>
                            {displayMessage('IS_INACTIVE_VERB_F')}
                        </SL_Button>
                    ) : (
                        <SL_Button variant={'secondary'} disabled={isLoading} className="ss:w-[150px] m-0" onClick={() => onChangeAccessKeyStatus(false)}>
                            {displayMessage('IS_ACTIVE_VERB_F')}
                        </SL_Button>
                    )}

                    {isEditing ? (
                        <SL_Button disabled={isLoading} className="ss:w-[150px] m-0" onClick={onChangeEdit}>
                            {displayMessage('SAVE')}
                        </SL_Button>
                    ) : (
                        <SL_Button disabled={isLoading} className="ss:w-[150px] m-0" onClick={onChangeEdit}>
                            {displayMessage('EDIT')}
                        </SL_Button>
                    )}
                </div>
            }
            maskClosable={false}
            width={650}
            bodyStyle={{ overflow: 'auto' }}
        >
            <div className="flex flex-col gap-3">
                <div className="text-base flex items-center">
                    <span className="font-bold mr-1 text-primary-900">{displayMessage('NAME')}:</span>

                    <Input size="small" value={name} disabled={!isEditing} readOnly={!isEditing} onChange={onChangeName} className="flex-1 text-lg"></Input>
                </div>

                <DetailKeyLabel label={displayMessage('CREATION_DATE')} value={formatFullTimeDate(accessKeyData?.createdAt)} />

                <DetailKeyLabel label={displayMessage('STATUS')} value={isKeyActive ? displayMessage('IS_ACTIVE_F') : displayMessage('IS_INACTIVE_F')} />

                {isKeyActive ? (
                    <DetailKeyLabel label={displayMessage('ACTIVATION_DATE')} value={formatFullTimeDate(accessKeyData?.last_activation_date)} />
                ) : (
                    <DetailKeyLabel label={displayMessage('INACTIVATION_DATE')} value={formatFullTimeDate(accessKeyData?.last_inactivation_date)} />
                )}

                <DetailKeyLabel label={displayMessage('CREATION_BY')} value={accessKeyData?.User?.full_name} />
            </div>

            <Divider className="bg-slate-600" />

            <div className="flex flex-col gap-3">
                <DetailKeyLabel label={displayMessage('ACCESS_LOGIN')} value={accessKeyData?.access_login} />
                <DetailKeyLabel label={displayMessage('ACCESS_PASSWORD')} value={accessKeyData?.access_password} />
                <div className="text-base flex items-center justify-center">
                    <span className="font-bold mr-1 text-primary-900">{displayMessage('CLIENT_URL_API')} ( Opcional ):</span>
                    <Popover
                        className="mb-2 mr-2 mt-2"
                        placement="bottom"
                        title={displayMessage('CLIENT_URL_API_HINT')}
                        content={
                            <div>
                                <div>{displayMessage('CLIENT_URL_API_FLOW_EXPLANATION')}</div>
                                <div>{displayMessage('CLIENT_URL_API_FLOW_POST', [clientUrlApi == '' ? 'https:// ip /api/v1/example' : clientUrlApi])}</div>
                                <div>{displayMessage('CLIENT_URL_API_FLOW_HEADERS', [HEADER_EXAMPLE])}</div>
                                <div>{displayMessage('CLIENT_URL_API_FLOW_BODY', [BODY_EXAMPLE])}</div>
                            </div>
                        }
                    >
                        <Button size="small" className="rounded-full border-primary-900">
                            ?
                        </Button>
                    </Popover>
                    <Input
                        size="middle"
                        value={clientUrlApi == '' ? displayMessage('NOT_INFORMED') : clientUrlApi}
                        disabled={!isEditing}
                        readOnly={!isEditing}
                        onChange={onChangeClientUrlApi}
                        className="flex-1 text-lg"
                    ></Input>
                </div>

                <div className="text-base flex items-center">
                    <span className="font-bold text-primary-900 mr-1">{displayMessage('XML_FORMAT')}:</span>

                    <Popover className="mb-2 mr-2 mt-2" placement="bottom" title={displayMessage('CLIENT_URL_API_HINT')} content={<div>{displayMessage('XML_FORMAT_EXPLAINED')}</div>}>
                        <Button size="small" className="rounded-full border-primary-900">
                            ?
                        </Button>
                    </Popover>

                    <Switch
                        checkedChildren={displayMessage('YES')}
                        unCheckedChildren={displayMessage('NO')}
                        disabled={!isEditing}
                        readOnly={!isEditing}
                        checked={formatToXML}
                        onChange={setFormatToXML}
                    />
                </div>
            </div>

            <div className="mb-4" />
        </Modal>
    )
}

function DetailKeyLabel({ label, value }) {
    return (
        <div className="text-base">
            <span className="font-bold mr-1 text-primary-900">{label}:</span> {value ?? ''}
        </div>
    )
}
