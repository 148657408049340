import React, { useContext, useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'
import { colors } from '../../services/styles'
import { Button } from 'antd'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { user_progenos_list_grid_column } from './grid_columns/user_progenos_grid_columns'
import { UserProgenosFilters } from './components/UserProgenosFilters'
import CustomToolbar from '../../components/grid/CustomToolbar'

export function ListUserProgenos({}) {
    const [people, setPeople] = useState([])
    const [isFiltersVisible, setIsFilterVisible] = useState(false)

    const gridRef = useGridApiRef()
    const { setShowLoading, mobile_mode } = useContext(GeneralContext)

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = async () => {
        try {
            const { data } = await api.people.list()
            setPeople(data)
        } finally {
            setShowLoading(false)
        }
    }

    const loadUsersProgenos = async (values) => {
        setShowLoading(true)
        try {
            if (values.by_client != null) {
                values.by_client = values.by_client.map((client) => {
                    return client.label
                })
            }
            const { data } = await api.people.list(values)
            setPeople(data)
        } finally {
            setShowLoading(false)
        }
    }

    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    return (
        <>
            <div className="h-full flex flex-col">
                <div className={`flex w-full bg-fit-green-700 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col items-center gap-4' : 'gap-2'}`}>
                    <div className={`flex-1 flex flex-wrap gap-2 rounded-t-md p-2`} style={{ background: colors.primary[900] }}>
                        <div className={'flex self-center'}>
                            <div className="self-center text-white font-semibold text-base">{displayMessage('LIST_PROGENOS_USER')}</div>
                        </div>

                        <div className="flex-1 flex justify-end gap-2">
                            <Button
                                type="default"
                                shape="circle"
                                onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                                icon={
                                    !isFiltersVisible ? (
                                        <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    ) : (
                                        <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    )
                                }
                            ></Button>
                        </div>
                    </div>
                </div>

                {isFiltersVisible ? <UserProgenosFilters handleOnSearchProgenosUsers={loadUsersProgenos} filterHeight={'20vh'} /> : <div />}

                <div style={{ width: '100%', flex: 1 }}>
                    <SL_Grid
                        rows={people ?? []}
                        apiRef={gridRef}
                        columns={user_progenos_list_grid_column}
                        style={{ marginBottom: 50 }}
                        components={{
                            Toolbar: () => <CustomToolbar />,
                        }}
                    />
                </div>
            </div>
        </>
    )
}
