import { LoginOutlined } from '@ant-design/icons'
import { ConfigProvider, Menu as AntdMenu } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useContext } from 'react'
import menus, { sideMenuItensIconsStyle } from './options/Menus'
import { Link, useLocation } from 'react-router-dom'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/styles'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

export function Menu({ collapsed, isSideMenu = true }) {
    const [menu_selected_keys, setMenuSelectedKeys] = useState([])
    const { setLoggedUser, isAdmin } = useContext(AuthContext)
    const location = useLocation()

    const menuOptions = useMemo(
        () => [
            ...menus.userSideMenuItens,
            ...(isAdmin ? menus.adminSideMenuItens : []),
            // ...menus.topMenuItens,
        ],
        [isAdmin]
    )

    useEffect(() => {
        selectMenuOnLoading()
    }, [location])

    //Seleciona o item do menu correspondente com a rota
    const selectMenuOnLoading = () => {
        let pathOption = window.location.pathname
        if (pathOption == '/') {
            setMenuSelectedKeys([menus.adminSideMenuItens[0].key])
            return
        }

        const index = pathOption.indexOf('/', 1)

        if (index > 0) {
            pathOption = pathOption.substring(0, index)
        }

        const foundCurrentOption = menuOptions.find((e) => e.key == pathOption)

        if (foundCurrentOption) {
            setMenuSelectedKeys([foundCurrentOption.key])
        }
    }

    const handleOnClickLogout = () => {
        setLoggedUser(null)
    }

    const handleOnClickItemMenu = (e) => {
        setMenuSelectedKeys([e.key])
    }

    function createMenuItem(item) {
        return {
            key: item.key,
            icon: <div className={menu_selected_keys && menu_selected_keys[0] == item.key ? `text-primary-500` : 'text-white'}>{item.icon}</div>,
            label: (
                <Link className={`text-base font-semibold ${menu_selected_keys && menu_selected_keys[0] == item.key ? `text-primary-500` : 'text-white'}`} to={item.key}>
                    <span>{item.title}</span>
                </Link>
            ),
        }
    }

    const listSideMenuItens = useMemo(
        () => [
            ...menuOptions.map((item) => createMenuItem(item)),

            {
                key: '/login',
                icon: <LoginOutlined style={{ ...sideMenuItensIconsStyle, color: `white` }} />,
                label: (
                    <Link className="text-white text-base" to={'/login'} onClick={handleOnClickLogout}>
                        <span>{displayMessage('LOGOUT')}</span>
                    </Link>
                ),
            },
        ],
        [menuOptions]
    )

    return (
        <ConfigProvider
            theme={{
                token: {
                    controlItemBgActiveHover: colors.primary[300],
                    colorBgTextHover: colors.primary[300],
                },
            }}
        >
            <AntdMenu
                mode="inline"
                defaultOpenKeys={['menu']}
                className={`flex-1 border-r-0 pt-5 h-full ${isSideMenu ? 'rounded-none' : 'rounded-sm'}`}
                selectedKeys={menu_selected_keys}
                inlineCollapsed={collapsed}
                onSelect={handleOnClickItemMenu}
                items={listSideMenuItens}
                style={{ backgroundColor: colors.primary[900] }}
            />
        </ConfigProvider>
    )
}
