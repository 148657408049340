module.exports = {
    aspectRatio: {
        equal: '1 / 1',
    },
    screens: {
        ss: '500px', // => @media (min-width: 500px) { ... }
        smb: '700px', // => @media (min-width: 700px) { ... }
    },
    width: {
        unset: 'unset',
    },
    height: {
        unset: 'unset',
    },
    colors: {
        primary: {
            100: '#F3cde4',
            200: '#Ecbcd9',
            300: '#Eca6d2',
            400: '#Da7db7',
            500: '#B35087',
            600: '#C38ee2',
            700: '#A15ec7',
            800: '#8b36bd',
            900: '#4d1980',
        },
        secondary: {
            100: '#ebf8fa',
            200: '#c2e9f0',
            300: '#99dbe6',
            400: '#70cddc',
            500: '#47bed2',
            600: '#2da5b8',
            700: '#23808f',
            800: '#195b66',
            900: '#0f373d',
        },

        //Cores Gerais
        'sl-red': {
            100: '#faebeb',
            200: '#efc2c2',
            300: '#e59999',
            400: '#db7171',
            500: '#d04848',
            600: '#d04848',
            700: '#8e2424',
            800: '#661a1a',
            900: '#3d1010',
        },
        'sl-gray': {
            50: '#fafafa',
            100: '#f4f4f5',
            200: '#e4e4e7',
            300: '#d4d4d8',
            400: '#a1a1aa',
            500: '#71717a',
            600: '#52525b',
            700: '#3f3f46',
            800: '#27272a',
            900: '#18181b',
        },
    },
}
