import { useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'

export function Totalizer({ eventCount, labels, messageTotal }) {
    const { mobile_mode } = useContext(GeneralContext)

    let sum = 0
    for (let index = 0; index < eventCount.length; index++) {
        sum = sum + eventCount[index]
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: mobile_mode ? 'column' : 'row',
                    justifyContent: 'space-evenly',
                    background: '#EFEFEF',
                    padding: 5,
                    rowGap: mobile_mode && 3,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: mobile_mode && 1,
                        width: !mobile_mode && '100%',
                        justifyContent: 'space-evenly',
                        padding: '5px',
                    }}
                    className="lg:flex-row flex-col lg:gap-2"
                >
                    <div
                        style={{
                            display: 'flex',
                            backgroundColor: 'white',
                            borderRadius: 5,
                            flex: mobile_mode && 1,
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8,
                                marginLeft: 8,
                                marginTop: 2,
                                marginBottom: 2,
                                fontWeight: 'bold',
                            }}
                        >{`${messageTotal}:  ${sum > 0 ? sum : '-'} `}</span>
                    </div>
                    {labels.map((e, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    borderRadius: 5,
                                    flex: mobile_mode && 1,
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: 8,
                                        marginLeft: 8,
                                        marginTop: 2,
                                        marginBottom: 2,
                                        fontWeight: 'bold',
                                    }}
                                >{`${e}:  ${eventCount[index] > 0 ? eventCount[index] : '-'} `}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
