import React, { useState, useContext, useEffect } from 'react'
import { Input, Modal, Form, Select, Row, Col, Button, Popover, Switch } from 'antd'
import api from '../../../services/api'
import { SL_Button } from '../../../components/buttons/Button'
import { displayMessage } from '../../../helpers/messages'

const BODY_EXAMPLE = ` "data": {
    "registry": {
        ...data
    },
    "progenos": {
        ...data
    }
}`

const HEADER_EXAMPLE = '{ "x-secret": "seu token"  }'

export function CreateAccessKeyModal({ client, onLoadClientAccessKeys, onClose }) {
    const [name, setName] = useState('')
    const [clientUrlApi, setClientUrlApi] = useState(null)
    const [urlHasError, setUrlHasError] = useState(false)
    const [formatToXML, setFormatToXML] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function onCloseModal() {
        if (isLoading) return

        onClose()
    }

    function onChangeName(e) {
        setName(e.target.value)
    }

    function onChangeClientUrlApi(e) {
        const inputValue = e.target.value

        if (inputValue.trim().length == 0) {
            setClientUrlApi(null)
            setUrlHasError(false)
            return
        }

        setUrlHasError(false)
        setClientUrlApi(inputValue)
    }

    async function onCreateClientAccessKey() {
        try {
            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

            if (!urlRegex.test(clientUrlApi)) {
                setUrlHasError(true)
                return
            }

            setIsLoading(true)

            const createBody = { name, clientUrlApi, client_id: client.id, is_xml_body_format: formatToXML }
            await api.clientAccessKeys.create(createBody)

            await onLoadClientAccessKeys()

            setTimeout(() => {
                onClose()
            }, 500)
        } catch (error) {
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }
    }

    return (
        <Modal
            title={<div className="text-xl font-bold text-fit-green-900 mr-4 text-primary-900">{displayMessage('ACCESS_KEY_CREATION')}</div>}
            open={true}
            onCancel={onCloseModal}
            centered={true}
            footer={
                <div className="flex justify-end flex-col-reverse ss:flex-row gap-2">
                    <SL_Button className="ss:w-[150px] bg-gray-500 border-transparent" onClick={onCloseModal}>
                        {displayMessage('CLOSE')}
                    </SL_Button>

                    <SL_Button disabled={isLoading} className="ss:w-[150px] m-0" onClick={onCreateClientAccessKey}>
                        {displayMessage('END_REGISTER')}
                    </SL_Button>
                </div>
            }
            maskClosable={false}
            width={650}
            bodyStyle={{ overflow: 'auto' }}
        >
            <div className="text-base">
                <span className="font-bold mr-1 text-primary-900">{displayMessage('CLIENT')}:</span> {client?.company_name ?? ''}
            </div>

            <div className="text-base font-bold mt-2 mb-1 text-primary-900">{displayMessage('CREATE_ACCESS_KEY_NAME')}:</div>

            <Input value={name} onChange={onChangeName} className="flex-1"></Input>

            <div className="flex flex-row mt-2 align-middle items-center">
                <div className="text-base font-bold mr-1 text-primary-900">{displayMessage('CLIENT_URL_API')} ( Opcional ):</div>
                <Popover
                    className="mb-2 mt-2"
                    placement="bottom"
                    title={displayMessage('CLIENT_URL_API_HINT')}
                    content={
                        <div>
                            <div>{displayMessage('CLIENT_URL_API_FLOW_EXPLANATION')}</div>
                            <div>{displayMessage('CLIENT_URL_API_FLOW_POST', [!clientUrlApi ? 'https:// ip /api/v1/example' : clientUrlApi])}</div>
                            <div>{displayMessage('CLIENT_URL_API_FLOW_HEADERS', [HEADER_EXAMPLE])}</div>
                            <div>{displayMessage('CLIENT_URL_API_FLOW_BODY', [BODY_EXAMPLE])}</div>
                        </div>
                    }
                >
                    <Button size="small" className="rounded-full border-primary-900">
                        ?
                    </Button>
                </Popover>
            </div>

            <Input value={clientUrlApi} onChange={onChangeClientUrlApi} className="flex-1"></Input>

            <div className="flex items-center mt-4">
                <div className="text-base font-bold text-primary-900 mr-1">{displayMessage('XML_FORMAT')}</div>

                <Popover className="mb-2 mr-2 mt-2" placement="bottom" title={displayMessage('CLIENT_URL_API_HINT')} content={<div>{displayMessage('XML_FORMAT_EXPLAINED')}</div>}>
                    <Button size="small" className="rounded-full border-primary-900">
                        ?
                    </Button>
                </Popover>

                <Switch checkedChildren={displayMessage('YES')} unCheckedChildren={displayMessage('NO')} checked={formatToXML} onChange={setFormatToXML} className="" />
            </div>

            {urlHasError && <div className="text-red-600 mt-2">{displayMessage('INVALID_URL')}</div>}
        </Modal>
    )
}
