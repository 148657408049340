export default {
    //BASE
    MENU: 'Menu',

    RESET_PASSWORD_FORM_SUCCESS: 'Senha redefinida com sucesso! Redirecionando para Login.',

    LIST_EVENT: 'Eventos',
    LIST_GENERAL: 'Geral',
    LIST_GENERAL_2: 'Geral 2',
    LIST_GENERAL_DETAIL: 'Detalhe Geral',
    GENERAL_TITLE: 'Titulo Modal',
    OPEN: 'Abrir',

    //Portal de Usuários

    ID: 'ID',

    DOCUMENT: 'Documento',
    SIZE: 'Tamanho',
    VERIFY: 'Verificar',
    WEEK: 'Semana',
    WEEKS: 'Semanas',
    DAY: 'Dia',
    DAY_OF_WEEK: 'Dia da semana',
    NUMBER_OF_WEEKS: 'Número de semanas',
    REGISTER: 'Cadastrar',
    USER: 'Colaborador',
    USERS: 'Colaboradores',
    INVALID_USER: 'Colaborador inválido',
    CREATE_USER: 'Cadastrar colaborador',
    EDIT_USER: 'Editar colaborador',
    CREATE_USER_SUCCESS: 'Seu usuário foi criado com sucesso!',
    UPDATE_USER_SUCCESS: 'Seu usuário foi alterado com sucesso!',
    MARK_ALL_AS_READ: 'Marcar todas como lidas',
    NOTIFICATIONS: 'Notificações',
    NOTIFICATION_LIST: 'Lista de notificações',
    REGISTER_NOTIFICATION: 'Cadastrar Notificação',
    NOTIFICATIONS_TITLE: 'Titulo da notificação',
    NOTIFICATIONS_DESCRIPTION: 'Mensagem da notificação',
    NOTIFICATIONS_CREATOR: 'Criador da notificação',
    NOTIFICATION_TYPE: 'Tipo da notificação',
    NOTIFICATION_NO_USER_OR_CLIENT_WARNING: 'Notificações devem ter, pelo menos, um colaborador ou uma empresa',
    MESSAGE: 'Mensagem',
    MESSAGE_DOCUMENT_MANDATORY: 'Mensagem é de preenchimento obrigatório!',
    QUANTITY_OF_USERS: 'Quantidade de colaboradores',

    MY_PROFILE: 'Meu perfil',
    SETTINGS: 'Configurações',
    LOGOUT: 'Log Out',
    EDIT: 'Editar',
    EDIT_PATIENT: 'Editar dados do paciente',
    SAVE_PATIENT: 'Salvar dados do paciente',
    SAVE_EDIT: 'Salvar edição',
    OTHERS: 'Outros',
    OTHER: 'Outro',
    ATENTION: 'Atenção',
    CLOSE: 'Fechar',
    INVALID: 'Inválido',
    CONFIRM: 'Confirmar',
    CONFIRM_CHANGE: 'Confirmar Troca',
    CONFIRM_PASSWORD_CHANGE: 'Confirmar Troca de Senha',
    CONFIRM_EMAIL_CHANGE: 'Confirmar Troca de Email',
    CONFIRM_PASSWORD_CHANGE_TO: 'Confirmar troca de senha para',
    CHANGE_PASSWORD: 'Alterar senha de login',
    CHANGE_PASSWORD_TITLE: 'Redefinição de senha',
    CONFIRM_CHANGE_PASSWORD: 'Confirmar e redefinir senha',
    CHANGE_EMAIL: 'Alterar email de login',
    CHANGE_EMAIL_PATIENT: 'Alterar email de login do paciente',
    ACTUAL_EMAIL: 'Email atual',
    NEW_EMAIL: 'Novo email',
    CONFIRM_NEW_EMAIL: 'Confirmar novo email',
    PASSWORD_CHANGE_SUCCESS: 'Senha trocada com sucesso!',
    EMAIL_CHANGE_SUCCESS: 'Email trocado com sucesso!',
    VERIFY_PASSWORD_EMAIL: `Paciente deve acessar o email de cadastro para obter a nova senha`,
    ONLY_NO_APPOINTMENT_PATIENTS: `Sem consulta agendada?`,
    ALL_PATIENTS: `Todos os pacientes`,
    REDEFINE_PASSWORD: 'Redefinir Senha',
    OLD_PASSWORD: 'Sua atual senha',
    NEW_PASSWORD: 'Sua nova senha',
    NEW_PASSWORD_SHORT: 'Nova senha',
    ENTER_NEW_PASSWORD_SHORT: 'Digite a nova senha...',
    DISPLAY_NEW_PASSWORD_SHORT: 'Coloque a nova senha',
    NEW_PASSWORD_CONFIRM: 'Confirmação da nova senha',
    ENTER_NEW_PASSWORD_CONFIRM: 'Digite a confirmação da nova senha...',
    DISPLAY_NEW_PASSWORD_CONFIRM: 'Coloque a confirmação da nova senha',
    YOUR_PASSWORD: 'Sua senha',
    CONFIRM_NEW_PASSWORD: 'Confirmar nova senha',
    CONFIRM_PASSWORD: 'Confirmar senha',
    FILL_ALL_FIELDS_RESET_PASSWORD: 'Digite todos os campos para redefinir a sua senha.',
    FILL_ALL_FIELDS_CHANGE_PASSWORD: 'Digite todos os campos para alterar sua senha.',
    FILL_ALL_FIELDS_CREATE_PASSWORD: 'É necessário informar a senha e a confirmação de senha!',
    PASSWORD_CONFIRMATION_FAILED: '"Sua nova senha" e "Confirmar nova senha" não são iguais.',
    FILL_ALL_FIELDS_CHANGE_EMAIL: 'Digite todos os campos para alterar seu email.',
    EMAIL_CONFIRMATION_FAILED: '"Seu novo email" e "Confirmar novo email" não são iguais.',
    RESET_TOKEN_INVALID: 'Token inválido. Você deve acessar esta página através do link enviado por email. Caso necessário, solicite uma nova redefinição de senha na página inicial.',
    RESET_PASSWORD_SUCCESS: 'Senha redefinida com sucesso. Redirecionando para tela inicial...',

    EDIT_REGISTERED_DATA: 'Editar Dados Cadastrais',

    LOGIN_DATA: 'Dados de Acesso',
    PERSONAL_DATA: 'Dados Pessoais',
    CLIENT_DATA: 'Dados do cliente',
    CLIENT_USERS: 'Usuários do cliente',
    CLIENT_ACCESS_KEYS: 'Chaves de Acesso',
    FIRST_NAME: 'Nome',
    FIRST_NAME_MANDATORY: 'Nome é de preenchimento obrigatório!',
    LAST_NAME: 'Sobrenome',
    LAST_NAME_MANDATORY: 'Sobrenome é de preenchimento obrigatório!',
    NUTRITIONIST_CREDENTIAL: 'CRN',
    PERSONAL_DOCUMENT: 'CPF',
    COMPANY_DOCUMENT: 'CNPJ',
    COMPANY_NAME: 'Nome da empresa',
    SEGMENT: 'Segmento',
    PERSONAL_EMAIL: 'Email pessoal',
    PERSONAL_EMAIL_CONFIRMATION: 'Confirmar email pessoal',
    PERSONAL_PHONE: 'Telefone pessoal',

    PERSONAL_ADDRESS: 'Endereço Pessoal',
    POSTAL_CODE: 'CEP',
    STREET: 'Logradouro',
    DISTRICT: 'Bairro',
    NUMBER: 'Número',
    COMPLEMENT: 'Complemento',
    CITY: 'Cidade',
    STATE: 'UF',

    GENDER: 'Gênero',
    MALE: 'Masculino',
    FEMALE: 'Feminino',
    BIRTHDAY: 'Data de Nascimento',
    AGE: 'Idade',
    YEARS: 'anos',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Digite seu email...',
    ENTER_PASSWORD: 'Digite sua senha...',
    PHONE: 'Telefone',
    WHATSAPP: 'WhatsApp',
    WEIGHT: 'Peso',
    HEIGHT: 'Altura',

    OBSERVATIONS: 'Observações',
    GENERAL_DATA: 'Dados Gerais',

    YES: 'Sim',
    NO: 'Não',

    DATE_TIME: 'Data / Hora',
    SEND_DATE: 'Data de Envio',
    LOCAL: 'Local',
    ADDRESS: 'Endereço',
    CREATION_DATE: 'Data da criação',
    FROM_THIS_DATE: 'A partir de ...',
    TO_THIS_DATE: 'Até ...',

    // END_APPOINTMENT: 'Local da Consulta',

    ERROR: 'Erro',
    SUCCESS: 'Sucesso',

    OK: 'OK',
    CANCEL: 'Cancelar',
    TYPE: 'Tipo',

    START: 'Início',
    END: 'Fim',
    START_TIME: 'Hora de Início',
    START_DATE: 'Data de Início',
    END_TIME: 'Hora de Fim',
    END_DATE: 'Data Fim',
    DURATION_DAYS: 'Duração em dias',
    LAST_ONE_F: 'Última',
    LAST_ONE_M: 'Último',
    CONTINUE: 'Continuar',

    NEXT_STEP: 'Próxima etapa',

    RECOVER_PASSWORD: 'Esqueci minha senha',

    ENTER: 'Entrar',
    CREATE_ACCOUNT: 'Criar Conta',
    DISPLAY_PASSWORD: 'Coloque sua senha',
    DISPLAY_CONFIRM_PASSWORD: 'Coloque sua confirmação de senha!',
    PASSWORD_MUST_BE_EQUAL: 'Senhas precisam ser iguais',
    DISPLAY_EMAIL: 'Coloque seu email',
    DISPLAY_REGISTERED_EMAIL: 'Informe o email cadastrado',
    INVALID_LOGIN: 'Login inválido',
    SUCCESS_NEW_PASSWORD: 'Sua nova senha foi enviada para o email informado!',
    FORGOT_PASSWORD_SUCCESS: 'Você receberá um email para alterar sua senha. Verifique sua caixa de emails.',

    MUST_CHOOSE_AUTOCOMPLETE: 'Um item deve ser selecionado.',

    ONLINE: 'Online',

    SEARCH: 'Consultar',

    LOGIN_ERROR: 'Erro ao efetuar login. ',
    CEP_FETCH_ERROR: 'Erro ao efetuar busca por CEP',
    CREATE_PATIENT_ERROR: 'Erro ao criar paciente',

    INCLUDE_CANCELED: 'Incluir canceladas?',
    NOT_INCLUDE_CANCELED: 'Ocultar canceladas?',

    DELETE_ATTACHMENT_QUESTION: 'Deseja excluir este anexo?',
    ACTION: 'Ação',

    SHOW_ONLY_NOT_READ: 'Mostrar apenas não lidas',
    SHOW_ALL_NOTIFICATIONS: 'Mostrar todas as notificações',
    SHOW_MORE: 'Mostrar mais',

    NONE_M: 'Nenhum',
    NONE_F: 'Nenhuma',
    ALL_M: 'Todos',
    ALL_F: 'Todas',
    ACTIVE: 'Ativos',
    INACTIVE: 'Inativos',

    INVALID_PERSONAL_EMAIL: 'Favor inserir um email pessoal válido!',
    INVALID_COMMERCIAL_EMAIL: 'Favor inserir um email comercial válido!',
    USER_ADDRESS_PERSONAL_EMPTY: 'Favor preencher o endereço Pessoal!',
    USER_ADDRESS_PERSONAL_INVALID: 'Endereço Pessoal inválido!',
    PERSONAL_PHONE_INFO_EMPTY: 'É necessário informar o telefone pessoal!',
    COMMERCIAL_PHONE_INFO_EMPTY: 'É necessário informar o telefone comercial!',
    COMMERCIAL_PHONE_INVALID: 'Telefone comercial inválido!',
    PERSONAL_PHONE_INVALID: 'Telefone pessoal inválido!',
    EMAIL_PERSONAL_EMPTY: 'É necessário informar o email pessoal!',
    EMAIL_COMMERCIAL_EMPTY: 'É necessário informar o email comercial!',
    EMAIL_PERSONAL_CONFIRMATION_EMPTY: 'É necessário confirmar o email pessoal!',
    EMAIL_PERSONAL_CONFIRMATION_INVALID: '"Email pessoal" e "Confirmação email pessoal" precisam ser iguais!',
    FULL_NAME_MANDATORY: 'Nome e Sobrenome são de preenchimento obrigatório!',
    COMPANY_DOCUMENT_MANDATORY: 'CNPJ é de preenchimento obrigatório!',
    COMPANY_NAME_MANDATORY: 'Nome da empresa é de preenchimento obrigatório!',
    INVALID_FILE_TYPE: 'Tipo de arquivo inválido, só é permitido anexar PDF ou imagens',
    REGISTER_EMAIL_WARNING: 'Esse vai ser o email utilizado para login de usuário',

    CODE: 'Código',
    TWO_FACTOR_AUTH_MODAL_TITLE: 'Etapa adicional de segurança',
    TWO_FACTOR_AUTH_SENT_MESSAGE: 'O código de autenticação foi enviado para o seu email',
    DISPLAY_2FA_CODE: 'Código de autenticação',
    SAVE: 'Salvar',
    USER_CRIATION: 'Criação de colaborador',
    WARNING_REGISTER_USER_EMAIL: 'Esse vai ser o email utilizado para login de usuário',
    SELECTION_MODULES: 'Seleção de Módulos',
    CONFIRM_MODULES: 'Confirmar módulos',
    SELECT_NEW_USER_INSTRUCTION: 'Selecione os módulos que o colaborador recém criado terá acesso',
    SKIP_STEP_INSTRUCTION: 'ou pule esta etapa',
    SKIP_STEP: 'Pular etapa',
    NAME: 'Nome',
    DEPARTMENT: 'Departamento',
    CPF: 'CPF',
    EMAIL_MANDATORY: 'Email é de preenchimento obrigatório!',
    CPF_MANDATORY: 'CPF é de preenchimento obrigatório!',
    CPF_DIGITS_WARNING: 'CPF deve conter 11 digitos',
    INVALID_EMAIL: 'Favor inserir um email válido!',
    INVALID_CONFIRMED_EMAIL: '"Email" e "Confirmar email" são diferentes!',
    PASSWORD_MANDATORY: 'Senha é de preenchimento obrigatório!',
    INVALID_CONFIRMED_PASSWORD: '"Senha" e "Confirmar senha" são diferentes!',
    CONFIRM_EMAIL: 'Confirmar email',
    PASSWORD: 'Senha',
    USER_LIST: 'Lista de Colaboradores',
    PERMISSIONS: 'Permissões',
    DETAIL_USER: 'Detalhe do colaborador',
    MANDATORY_FIELD: 'Este campo é obrigatório',
    MODULE_NAME: 'Nome',
    MODULE_DEPARTMENT: 'Departamento',
    MODULE_DESCRIPTION: 'Descrição',
    MODULE_URL: 'URL',
    TWO_FACTOR_AUTH: 'Autenticação por 2 fatores: ',
    GIVE_ACCESS: 'Conceder Acesso',
    REMOVE_ACCESS: 'Remover Acesso',

    LOGIN_TITLE: 'Nanotimize',
    CLIENT: 'Empresa',
    CLIENTS: 'Empresas',
    CLIENTS_LIST: 'Lista de Empresas',
    REGISTER_CLIENT: 'Cadastrar empresa',
    REGISTER_CLIENT_2: 'Cadastro de empresa',
    REGISTER_DATE: 'Data do cadastro',
    DETAIL_CLIENT: 'Detalhe da empresa',
    EDIT_CLIENT: 'Edição da empresa',
    UPDATE_CLIENT_SUCCESS: 'Empresa alterada com sucesso!',
    CREATE_CLIENT_SUCCESS: 'Empresa criada com sucesso!',
    CREATE_NOTIFICATION_SUCCESS: 'Notificação criada com sucesso!',
    IS_ACTIVE: 'Ativo',
    IS_ACTIVE_F: 'Ativa',
    IS_ACTIVE_VERB_F: 'Ativar',
    IS_INACTIVE: 'Inativo',
    IS_INACTIVE_F: 'Inativa',
    IS_INACTIVE_VERB_F: 'Inativar',
    SITUATION: 'Situação',
    ERROR_UPDATE_CLIENT_STATUS: 'Não é possível atualizar o status da empresa. ',
    CONFIRM_CHANGE_STATUS_CLIENT: 'Tem certeza que deseja alterar o status da empresa?',
    CONFIRM_CHANGE_NEED_APPOINTMENT: 'Confirmar necessidade de consulta para o paciente?',
    REQUEST_PASSWORD_RESET: 'Solicitar troca de senha',
    RESET_PASSWORD: 'Troca de senha',
    RESET_PASSWORD_USER_CONFIRM: 'Confirmar trocar de senha para o seguinte usuário:',

    REGISTER_USER_2: 'Cadastro de usuário',
    USER_TYPE: 'Tipo de usuário',
    USER_TYPE_MANDATORY: 'Tipo é de preenchimento obrigatório!',
    USER_MANDATORY: 'Usuário(s) é de preenchimento obrigatório!',
    TYPE_MANDATORY: 'Tipo é de preenchimento obrigatório!',
    CLIENT_MANDATORY: 'Empresa é de preenchimento obrigatório!',
    QUANTITY_OF_CLIENTS: 'Quantidade de empresas',

    CLEAN_FILTERS: 'Limpar filtros',
    SEARCH_FILTERS: 'Filtros de Consulta',
    OPEN_FILTERS: 'Abrir Filtros',
    CLOSE_FILTERS: 'Fechar Filtros',
    STATUS: 'Status',
    TITLE: 'Título',
    TITLE_AND_OR_DESCRIPTION: 'Titulo e/ou descrição',
    DESCRIPTION: 'Descrição',
    TITLE_MANDATORY: 'Título é de preenchimento obrigatório!',
    DURATION_MANDATORY: 'Duração é de preenchimento obrigatório!',
    START_DAY_MANDATORY: 'Dia de início é de preenchimento obrigatório!',
    CATEGORY_MANDATORY: 'Categoria é de preenchimento obrigatório!',
    RANKING_TYPE_MANDATORY: 'Tipo do é de preenchimento obrigatório!',

    CATEGORY: 'Categoria',
    DURATION: 'Duração',

    NO_WHITE_SPACE_WARNING: 'Este campo não pode estar em branco',
    INVALID_INPUT_DATA: 'Informe valores válidos',

    IN_DAYS: 'em dias',

    CONTINUE_REGISTER: 'Continuar cadastrando...',

    QUESTION: 'Pergunta',
    QUESTIONS: 'Perguntas',
    DASHBOARD: 'Dashboard',
    GENERIC_SELECT_PLACEHOLDER: 'Selecione uma {0}',
    GENERIC_AUTOCOMPLETE_PLACEHOLDER: 'Pesquise por um {0}',

    NOT_ANSWERED: 'Não respondidas',
    ANSWERED_QUIZ: 'Finalizados / Respondidos',
    ANSWERED_AT: 'Respondido em',
    VIEW_DATA: 'Visualizar dados',
    VIEW_ANSWERS: 'Visualização de respostas',
    NO_DATA_PROVIDED: 'Sem dados para visualizar',

    SHOW_DATE: 'Data Programada',
    SEARCH_FILTER: 'Filtros da Consulta',

    //NANOTIMIZE

    LOAD_CLIENTS_ERROR: 'Erro ao carregar empresas',
    LOAD_USERS_ERROR: 'Erro ao carregar usuários',
    LOAD_EVENTS_ERROR: 'Erro ao carregar eventos',
    LOAD_EXAMS_ERROR: 'Erro ao carregar exames',
    EVENT_TYPE: 'Tipo do evento',
    EVENT_LIST: 'Lista de Eventos',
    DETAIL_EVENT: 'Detalhe do evento',
    REGISTER_EVENT: 'Cadastrar evento',
    LINKED_USER: 'Usuário solicitado',
    USER: 'Usuário',
    DATE: 'Data',
    REALIZAR_ANALISE: 'Realizar análise',
    CADASTRO_COLETA: 'Cadastrar coleta',
    CONSULTAR_RESULTADOS: 'Consultar resultados',
    SEARCH_CLIENT: 'Pesquise a empresa',
    SYSTEM_CREATING_DATE: 'Data de inclusão no sistema',
    QUANTITY_OF_LINKED_USERS: 'Quantidade de usuários solicitados',
    ETHNICITY: 'Etnia',
    DELETE_USER: 'Excluir usuário',
    DELETE_USER_CONFIRM: 'Deseja excluir o usuário?',
    LIST_USER: 'Lista de Usuários',
    LIST_PROGENOS_USER: 'Lista de Usuários Progenos',
    USERS_NANO: 'Usuários',
    USERS_PROGENOS: 'Usuários Progenos',
    USER_DETAIL: 'Detalhe do usuário',
    USER_CREATE: 'Cadastrar usuário',
    SYSTEM_CREATING_DATE_MANDATORY: 'Data de inclusão no sistema é obrigatória',
    CLIENT_SELECT: 'Selecione as empresas',
    COMPANY: 'Empresa',
    REGISTRY_DATE: 'Data de registro',
    REGISTRY_SUB: 'Identificador AWS',
    TOTAL_EVENTS: 'Total de eventos',
    TOTALIZERS: 'Totalizadores',
    EXAM_TOTALIZERS: 'Totalizadores dos exames',
    REDIRECTING_TO_LOGIN: 'Senha atualizada com sucesso! Redirecionando para o login',
    DEFAULT_PASSWORD: '* Senha padrão quando o usuário é criado: ',
    END_REGISTER: 'Finalizar cadastro',
    CLIENT_DATA_SAVED_SUCCESS: 'Dados da empresa salvos com sucessso',
    USER_DATA_SAVED_SUCCESS: 'Dados do usuário salvos com sucessso',
    REGISTRY_SUB_MANDATORY: 'Identificador é de preenchimento obrigatório!',
    EXPORT: 'Exportar',
    EXAMS: 'Exames',
    EXAM: 'Exame',
    VINCULATED_EXAMS_QUANTITY: 'Quantidade de exames solicitados',
    VINCULATED_EXAMS: 'Exames Solicitados',
    NO_VINCULATED_EXAMS: 'Sem Coletas Solicitadas',
    EXAM_TOTALIZER_INFO: `
        Esse totalizador mostra a quantidade de cada exame solicitado aos eventos do tipo
        "Cadastrar Coleta" e "Consultar Resultado". Os valores são alterados com base nos filtros aplicados no
        início da tela.
    `,
    EMPTY: '-',
    SELECT_ALL_EXAMS: 'Todos os Exames',
    ID: 'ID',
    PROTOCOL_NUMBER: 'Nº Prontuário',
    ACCESS_LOGIN: 'Login de Acesso',
    ACCESS_PASSWORD: 'Senha de Acesso',
    CREATION_DATE: 'Data de criação',
    CREATION_BY: 'Usuário de criação',
    ACTIVATION_DATE: 'Data de Ativação',
    INACTIVATION_DATE: 'Data de Inativação',
    LIST_ACCESS_KEYS: 'Lista de Chaves de Acesso',
    ACCESS_KEY_CREATION: 'Criação de Chave de Acesso',
    CREATE_ACCESS_KEY: 'Criar Chave de Acesso',
    DETAIL_ACCESS_KEY: 'Detalhe da Chave de Acesso',
    CREATE_ACCESS_KEY_NAME: 'Nome da Chave de Acesso',

    CLIENT_URL_API: 'Client Url Api',
    NOT_INFORMED: 'Não Informado',

    CLIENT_URL_API_HINT: 'Como vai funcionar',
    CLIENT_URL_API_FLOW_EXPLANATION: 'Ao cadastar esta url, quando o webHook for ativado, será enviada uma requisição para a mesma no seguinte formato: ',
    CLIENT_URL_API_FLOW_POST: 'POST: {0}',
    CLIENT_URL_API_FLOW_HEADERS: 'HEADERS: {0}',
    CLIENT_URL_API_FLOW_BODY: 'BODY: {0}',
    INVALID_URL: 'Url Inválida!',

    XML_FORMAT: 'Formatar para XML?',
    XML_FORMAT_EXPLAINED: 'Caso cadastrado uma url para a api do cliente, se a opção de formatar para XML estiver ativa, será enviado um corpo de requisição com formato XML',

    GROUP_TITLE: 'Grupo: {0}',
    SUBGROUP_TITLE: 'Subgrupo: {0}',
    
    MEDICAL_GROUP: 'Grupo',
    MEDICAL_SUBGROUP: 'Subgrupo',

    REFERENCE_METHOD: 'Método de referência',
    UNIT: 'Unidade de medida',
    MALE_REFERENCE:'Referência masculina',
    FEMALE_REFERENCE:'Referência feminina',  
    
    EXAM_DETAIL: 'Detalhes do exame',
    SELECT_EXAM_ERROR: 'É necessário selecionar um exame',
    EXAM_DATA_SAVED_SUCCESS: 'Dados do exame salvos com sucesso'
}
