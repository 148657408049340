import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Input, Button, Form, Tabs, Spin } from 'antd'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { ERegExp } from '../../../enums/ERegExp'
import ListPeopleFromClient from './ListPeopleFromClient'
import { DateTime } from 'luxon'
import { EAlertTypes } from '../../../enums/EAlertTypes'
import { ListAcessKeys } from './ListAcessKeys'

export const ClientDetail = () => {
    const [form] = Form.useForm()
    const { clientId } = useParams()

    const [isLoadingData, setIsLoadingData] = useState(true)
    const [readOnly, setReadOnly] = useState(true)
    const [client, setClient] = useState(null)
    const dateFormat = 'dd/LL/yyyy'

    let hasChanged = false

    const { setShowLoading, mobile_mode, showAlert } = useContext(GeneralContext)

    useEffect(() => {
        loadInitialData()
    }, [])

    async function loadInitialData() {
        try {
            await loadClientInfo(clientId)
        } finally {
            setTimeout(() => {
                setIsLoadingData(false)
            }, 1000)
        }
    }

    const loadClientInfo = async (id) => {
        try {
            let tempClient = await api.client.get(id)
            tempClient.data.createdAt = DateTime.fromISO(tempClient.data.createdAt).startOf('day').toFormat(dateFormat)
            form.setFieldsValue(tempClient.data)
            setClient(tempClient.data)
        } catch (error) {
            // alert('Empresa inválida', 'warning')
        }
    }

    const onFinishEdit = async (values) => {
        if (readOnly) {
            setReadOnly((current) => !current)
            return
        }

        setShowLoading(true)

        try {
            if (hasChanged) {
                await api.client.update(client.id, values)
                showAlert('CLIENT_DATA_SAVED_SUCCESS', EAlertTypes.SUCCESS)
            }
        } finally {
            setReadOnly((current) => !current)
            setShowLoading(false)
        }
    }

    const onCloseEdit = () => {
        setReadOnly(true)
        loadClientInfo(clientId)
    }
    const onOpenEdit = () => {
        readOnly ? setReadOnly(false) : form.submit()
    }

    if (isLoadingData) {
        return (
            <div
                style={{
                    width: '100%',
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spin size="large" />
            </div>
        )
    }

    return (
        <>
            <div className="font-bold text-lg text-appian-blue text-center" style={{ marginBottom: 10 }}>
                {displayMessage('DETAIL_CLIENT')}
            </div>

            <div className="flex flex-1 flex-col">
                <Tabs
                    className="h-full"
                    defaultActiveKey="1"
                    items={[
                        {
                            label: displayMessage('CLIENT_DATA'),
                            key: '1',
                            children: (
                                <>
                                    <Form
                                        onFinish={onFinishEdit}
                                        layout="vertical"
                                        requiredMark="optional"
                                        form={form}
                                        disabled={readOnly}
                                        onValuesChange={() => {
                                            hasChanged = true
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: mobile_mode ? '80%' : '50%',
                                                flexDirection: 'column',
                                                gap: 7,
                                                display: 'flex',
                                            }}
                                        >
                                            <Form.Item
                                                label={<span className="text-fit-green-900 font-semibold">{displayMessage('COMPANY_NAME')}:</span>}
                                                name="company_name"
                                                className={`w-full m-0`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: displayMessage('COMPANY_NAME_MANDATORY'),
                                                    },
                                                    {
                                                        pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                                        message: displayMessage('INVALID_INPUT_DATA'),
                                                    },
                                                    {
                                                        whitespace: false,
                                                        message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                    },
                                                ]}
                                            >
                                                <Input type="text" className="text-fit-green-900" />
                                            </Form.Item>

                                            <Form.Item
                                                label={<span className="text-fit-green-900 font-semibold"> {displayMessage('SYSTEM_CREATING_DATE')}:</span>}
                                                name="createdAt"
                                                className={`w-full m-0`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: displayMessage('SYSTEM_CREATING_DATE_MANDATORY'),
                                                    },
                                                    {
                                                        whitespace: false,
                                                        message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                    },
                                                ]}
                                            >
                                                <Input disabled type="text" className="text-fit-green-900" />
                                            </Form.Item>
                                        </div>
                                    </Form>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            flexDirection: mobile_mode ? 'column' : 'row',
                                            maxWidth: mobile_mode ? '80%' : '50%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: mobile_mode ? '100%' : 280,
                                                justifyContent: mobile_mode ? 'space-between' : 'flex-start',
                                                flexDirection: mobile_mode ? 'column' : 'row',
                                            }}
                                        >
                                            <Button style={{ minWidth: 80, marginTop: 15 }} type={'primary'} onClick={onOpenEdit}>
                                                {readOnly ? displayMessage('EDIT') : displayMessage('SAVE_EDIT')}
                                            </Button>
                                            {!readOnly ? (
                                                <Button
                                                    style={{
                                                        minWidth: 80,
                                                        marginTop: 15,
                                                        marginLeft: mobile_mode ? 0 : 10,
                                                    }}
                                                    type={'primary'}
                                                    onClick={onCloseEdit}
                                                    danger
                                                >
                                                    {displayMessage('CLOSE')}
                                                </Button>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                        {
                            label: displayMessage('CLIENT_USERS'),
                            key: '2',
                            style: { height: '100%' },
                            children: <ListPeopleFromClient client={client}></ListPeopleFromClient>,
                        },
                        {
                            label: displayMessage('CLIENT_ACCESS_KEYS'),
                            key: '3',
                            style: { height: '100%' },
                            children: <ListAcessKeys client={client}></ListAcessKeys>,
                        },
                    ]}
                />
            </div>
        </>
    )
}
