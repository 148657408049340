import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { SL_Button } from '../../../components/buttons/Button'
import logo from '../../../assets/logo.png'
import Sider from 'antd/lib/layout/Sider'
import { Menu } from './Menu'

export function SideMenu() {
    const [collapsed, setCollapsed] = useState(true)

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} width={300}>
            <div className="flex flex-col bg-white h-full ">
                <div
                    className={`flex flex-row justify-evenly mb-0 transition-all duration-1000 border-[0px] items-center ${collapsed ? 'h-16' : 'h-36 border-r-[1px] border-solid border-primary-900'}`}
                >
                    {!collapsed && <img height={'70%'} className={`aspect-video ${collapsed ? 'p-0' : 'p-2'} items-center`} src={logo} />}

                    <div className={`flex justify-center items-center transition-all duration-1000 ${collapsed ? 'border-0' : ''}`}>
                        <SL_Button
                            type="primary"
                            onClick={toggleCollapsed}
                            className={`justify-center rounded-r-2xl w-[54px] h-[41px]`}
                            icon={collapsed ? <MenuUnfoldOutlined className="items-center text-2xl flex" /> : <MenuFoldOutlined className="items-center text-2xl flex" />}
                        />
                    </div>
                </div>

                <Menu collapsed={collapsed} />
            </div>
        </Sider>
    )
}
