import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { SL_Button } from '../../components/buttons/Button'
import { displayMessage } from '../../helpers/messages'
import { user_grid_column } from './grid_columns/user_grid_column'
import api from '../../services/api'
import { CloseOutlined, EditOutlined, FilterOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { colors } from '../../services/styles'
import UserFilters from '../user/components/UserFilters'
import CreateUser from './components/CreateUser'
import { useNavigate } from 'react-router-dom'

export const ListUsers = () => {
    const [isFiltersVisible, setIsFilterVisible] = useState(false)
    const [users, setUsers] = useState([])
    const [isGridLoading, setIsGridLoading] = useState(true)
    const [isModalCreateUserVisible, setIsModalCreateUserVisible] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [eUserTypes, setEUserTypes] = useState([])

    const { showAlert, mobile_mode } = useContext(GeneralContext)

    const navigate = useNavigate()

    useEffect(() => {
        loadUserFilterList()
    }, [])

    async function loadUserFilterList(values) {
        try {
            const { data: enumUserTypes } = await api.enums.find('EUserTypes')
            setEUserTypes(enumUserTypes)
            const { data } = await api.user.list(values)
            setUsers(data ?? [])
        } catch (error) {
            showAlert('LOAD_EVENTS_ERROR')
        } finally {
            setIsGridLoading(false)
        }
    }

    const handleRowChange = (e) => {
        setCurrentUser(e?.row)
    }

    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    function handleOnCloseModalCreateUser() {
        setIsModalCreateUserVisible(false)
    }

    function handleOnOpenModalCreateUser() {
        setIsModalCreateUserVisible(true)
    }

    const handleOnGoToDetailClient = () => {
        navigate('' + currentUser.id)
    }

    return (
        <>
            {isModalCreateUserVisible && <CreateUser handleOnCloseModal={handleOnCloseModalCreateUser} refreshUsers={loadUserFilterList} eUserTypes={eUserTypes} />}
            <div className="flex flex-col flex-1 h-full">
                <div style={{ background: colors.primary[900] }} className={`flex p-2 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col items-center gap-4 flex-wrap' : 'gap-2'}`}>
                    <div className="self-center text-white font-semibold text-base">{displayMessage('LIST_USER')}</div>
                    <div className={'flex flex-1 justify-end gap-2 flex-wrap'}>
                        <SL_Button
                            type="default"
                            className={`flex items-center flex-1 ${mobile_mode ? 'w-[200px]' : 'max-w-[200px]'}`}
                            icon={<EditOutlined className="text-base flex items-center" />}
                            onClick={handleOnGoToDetailClient}
                            disabled={!currentUser}
                        >
                            <span className="text-fit-green-800 font-semibold flex-1">{displayMessage('USER_DETAIL')}</span>
                        </SL_Button>

                        <SL_Button
                            type="default"
                            className={`flex items-center flex-1 ${mobile_mode ? 'w-[200px]' : 'max-w-[200px]'}`}
                            icon={<UserAddOutlined className="text-base flex items-center" />}
                            onClick={handleOnOpenModalCreateUser}
                        >
                            <span className="text-fit-green-800 font-semibold flex-1">{displayMessage('USER_CREATE')}</span>
                        </SL_Button>

                        <Button
                            type="default"
                            shape="circle"
                            onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                            icon={
                                !isFiltersVisible ? (
                                    <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                ) : (
                                    <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                )
                            }
                        ></Button>
                    </div>
                </div>
                {isFiltersVisible ? <UserFilters handleOnSearchUsers={loadUserFilterList} filterHeight={'20vh'} /> : <div />}
                <div className={`w-full flex-1 min-h-[400px] smb:min-h-0 mb-[20px]`}>
                    <SL_Grid rows={users} columns={user_grid_column} loading={isGridLoading} onRowClick={handleRowChange} />
                </div>
            </div>
        </>
    )
}
