import { displayMessage } from '../../../helpers/messages'

export const client_grid_column = [
    {
        field: 'id',
        headerName: displayMessage('CODE'),
        flex: 1,
    },
    {
        field: 'company_name',
        headerName: displayMessage('CLIENT'),
        valueGetter: (params) => {
            return params?.row?.company_name || ''
        },
        flex: 1,
    },
    {
        field: '',
        headerName: displayMessage('QUANTITY_OF_LINKED_USERS'),
        flex: 4,
        valueGetter: (params) => {
            return params?.row?.People?.length || 0
        },
    },
]
