import { InfoCircleOutlined } from '@ant-design/icons'
import { displayMessage } from '../../helpers/messages'
import { colors } from '../../services/styles'
import { Divider } from 'antd'
import React, { useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'

export function ExamTotalizer({ totalizers }) {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <div className="flex flex-col justify-center items-center gap-y-3 p-3 bg-[#EFEFEF]">
            <div className="flex gap-x-3 items-center justify-center p-5 bg-primary-100 rounded lg:w-2/3">
                <InfoCircleOutlined style={{ fontSize: '30px', color: colors.primary[700] }} />
                <span>{displayMessage('EXAM_TOTALIZER_INFO')}</span>
            </div>

            <div className="flex flex-wrap gap-x-3 justify-center">
                {totalizers &&
                    totalizers.map((group) => (
                        <div key={group.description} className={`rounded mt-1 bg-white h-min overflow-auto ${mobile_mode && 'w-[300px]'}`} style={{ border: `1px solid ${colors.primary[700]}` }}>
                            <p className={`bg-primary-700 p-1 flex flex-row ${mobile_mode ? 'w-[500px] justify-start' : 'w-full justify-center'} items-center font-bold text-white`}>
                                {group.description}
                            </p>
                            <div className="flex flex-row w-full h-full">
                                {group.subgroups.map((subgroup, index) => {
                                    const last_element_index = group.subgroups.length - 1
                                    return (
                                        <>
                                            <div className="w-full" key={subgroup.description}>
                                                <div className="my-2">
                                                    {subgroup.exams.map((exam) => (
                                                        <div key={exam.description} className="flex justify-between gap-x-5 px-2 ">
                                                            <span>{exam.description}</span>
                                                            <span>{exam.count}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {index != last_element_index && <Divider type="vertical" className="h-auto m-0" />}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}
