import { ProfileOutlined, TeamOutlined, BellOutlined, DashboardOutlined, BankOutlined, UserOutlined, NotificationOutlined, FileOutlined, CalendarOutlined, FileTextOutlined } from '@ant-design/icons'
import { displayMessage } from '../../../../helpers/messages'

export const sideMenuItensIconsStyle = {
    fontSize: 20,
}

export const topMenuItensIconsStyle = {
    fontSize: 20,
}

export default {
    //Somente para visualização do admin do sistema - Menu Lateral

    adminSideMenuItens: [
        {
            key: '/users',
            icon: <TeamOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('USERS_NANO'),
        },
    ],

    //Visualização para todos os usuários - Menu Lateral
    userSideMenuItens: [
        {
            key: '/clients',
            icon: <BankOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('CLIENTS'),
        },
        {
            key: '/events',
            icon: <CalendarOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('LIST_EVENT'),
        },
        {
            key: '/users-progenos',
            icon: <UserOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('USERS_PROGENOS'),
        },
        {
            key: '/exams',
            icon: <FileTextOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('EXAMS'),
        },
    ],

    //Visualização para todos os usuários - Menu Superior
    topMenuItens: [
        {
            key: '/profile',
            icon: <UserOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('MY_PROFILE'),
        },
    ],
}
