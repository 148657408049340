import { Button, Form, Input, Select, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const { Option } = Select

export default function ExamsDetail() {
    const [form] = Form.useForm()
    const { examsId } = useParams()
    const [readOnly, setReadOnly] = useState(true)
    const [examTypes, setExamTypes] = useState([])

    const { setShowLoading, mobile_mode, showAlert } = useContext(GeneralContext)

    var examTypeList = Object.values(examTypes).map((examType) => {
        return { value: examType.code, label: examType.description }
    })

    useEffect(() => {
        loadExamInfo(examsId)
    }, [])

    const loadExamInfo = async (id) => {
        try {
            const { data } = await api.medicalExams.find(id)
            form.setFieldsValue(data)
        } catch (error) {
            alert(displayMessage('INVALID_EXAM'), 'warning')
        }
    }

    const onFinishEdit = async (values) => {
        if (readOnly) {
            setReadOnly((current) => !current)
            return
        }

        setShowLoading(true)
        try {
            const { data } = await api.medicalExams.update(examsId, values)
            showAlert('EXAM_DATA_SAVED_SUCCESS', EAlertTypes.SUCCESS)
        } finally {
            setReadOnly((current) => !current)
            setShowLoading(false)
            loadExamInfo(examsId)
        }
    }

    const onCloseEdit = () => {
        setReadOnly(true)
        loadExamInfo(examsId)
    }

    const onOpenEdit = () => {
        readOnly ? setReadOnly(false) : form.submit()
    }

    return (
        <>
            <div className="font-bold text-lg text-appian-blue text-center" style={{ marginBottom: 10 }}>
                {displayMessage('EXAM_DETAIL')}
            </div>
            <div className="w-full flex-1">
                <Tabs
                    defaultActiveKey="1"
                    className="h-full"
                    tabBarStyle={{
                        display: 'flex',
                        height: '50px',
                    }}
                    items={[
                        {
                            label: displayMessage('EXAM_DATA'),
                            key: '1',
                            children: (
                                <>
                                    <Form onFinish={onFinishEdit} layout="vertical" form={form} >
                                        <div
                                            style={{
                                                maxWidth: '100%',
                                                flexDirection: 'column',
                                                gap: 10,
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: mobile_mode ? 'column' : 'row',
                                                    gap: 8,
                                                }}
                                            >
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold"> {displayMessage('DESCRIPTION')}:</span>}
                                                    name="description"
                                                    className={`w-full m-0`}
                                                    disabled={true}
                                                  
                                                    style={{ flex: 1 }}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={true} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('REFERENCE_METHOD')}:</span>}
                                                    name="reference_method"
                                                    className={`w-full m-[0]`}
                                                    style={{
                                                        marginLeft: mobile_mode ? 0 : 5,
                                                        flex: 1,
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('EXAM_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                                
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('UNIT')}:</span>}
                                                    name="unit"
                                                    className={`w-full m-[0]`}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('EXAM_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 8,
                                                }}
                                            >
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('MALE_REFERENCE')}:</span>}
                                                    name="male_reference_value"
                                                    className={`w-full m-[0]`}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('EXAM_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('FEMALE_REFERENCE')}:</span>}
                                                    name="female_reference_value"
                                                    className={`w-full m-[0]`}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('EXAM_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            flexDirection: mobile_mode ? 'column' : 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: mobile_mode ? '100%' : 300,
                                                justifyContent: mobile_mode ? 'space-between' : 'flex-start',
                                                flexDirection: 'row',
                                                marginTop: mobile_mode ? 20 : 0,
                                            }}
                                        >
                                            <Button style={{ minWidth: 80, marginTop: 15 }} type={'primary'} onClick={onOpenEdit}>
                                                {readOnly ? displayMessage('EDIT') : displayMessage('SAVE_EDIT')}
                                            </Button>
                                            {!readOnly ? (
                                                <Button
                                                    style={{
                                                        minWidth: 80,
                                                        marginTop: 15,
                                                        marginLeft: mobile_mode ? 0 : 10,
                                                    }}
                                                    type={'primary'}
                                                    onClick={onCloseEdit}
                                                    danger
                                                >
                                                    {displayMessage('CLOSE')}
                                                </Button>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    )
}