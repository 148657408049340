import { Button, Form, Input, Select, Switch, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ERegExp } from '../../../enums/ERegExp'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const { Option } = Select

export default function UserDetail() {
    const [form] = Form.useForm()
    const { userId } = useParams()
    const [readOnly, setReadOnly] = useState(true)
    const [eUserTypes, setEUserTypes] = useState([])

    const { setShowLoading, mobile_mode, showAlert } = useContext(GeneralContext)

    var userTypeList = Object.values(eUserTypes)
        .filter((userType) => userType.code >= eUserTypes.ADMIN.code)
        .map((userType) => {
            return { value: userType.code, label: userType.description }
        })

    useEffect(() => {
        loadUserInfo(userId)
    }, [])

    const loadUserInfo = async (id) => {
        try {
            const { data: enumUserTypes } = await api.enums.find('EUserTypes')
            setEUserTypes(enumUserTypes)
            let tempUser = await api.user.find(id)
            form.setFieldsValue(tempUser.data)
        } catch (error) {
            alert(displayMessage('INVALID_USER'), 'warning')
        }
    }
    const onFinishEdit = async (values) => {
        if (readOnly) {
            setReadOnly((current) => !current)
            return
        }

        setShowLoading(true)
        try {
            const { data } = await api.user.update(userId, values)
            showAlert('USER_DATA_SAVED_SUCCESS', EAlertTypes.SUCCESS)
        } finally {
            setReadOnly((current) => !current)
            setShowLoading(false)
            loadUserInfo(userId)
        }
    }

    const onCloseEdit = () => {
        setReadOnly(true)
        loadUserInfo(userId)
    }

    const onOpenEdit = () => {
        readOnly ? setReadOnly(false) : form.submit()
    }

    return (
        <>
            <div className="font-bold text-lg text-appian-blue text-center" style={{ marginBottom: 10 }}>
                {displayMessage('USER_DETAIL')}
            </div>
            <div className="w-full flex-1">
                <Tabs
                    defaultActiveKey="1"
                    className="h-full"
                    tabBarStyle={{
                        display: 'flex',
                        height: '50px',
                    }}
                    items={[
                        {
                            label: displayMessage('PERSONAL_DATA'),
                            key: '1',
                            children: (
                                <>
                                    <Form onFinish={onFinishEdit} layout="vertical" requiredMark="optional" form={form}>
                                        <div
                                            style={{
                                                maxWidth: '100%',
                                                flexDirection: 'column',
                                                gap: 10,
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: mobile_mode ? 'column' : 'row',
                                                    gap: 8,
                                                }}
                                            >
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('FIRST_NAME')}:</span>}
                                                    name="first_name"
                                                    className={`w-full m-[0]`}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('FIRST_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                                            message: displayMessage('INVALID_INPUT_DATA'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('LAST_NAME')}:</span>}
                                                    name="last_name"
                                                    className={`w-full m-[0]`}
                                                    style={{
                                                        marginLeft: mobile_mode ? 0 : 5,
                                                        flex: 1,
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('LAST_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                                            message: displayMessage('INVALID_INPUT_DATA'),
                                                        },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                            </div>

                                            <Form.Item
                                                label={<span className="text-fit-green-900 font-semibold"> {displayMessage('EMAIL')}:</span>}
                                                name="email"
                                                className={`w-full m-0`}
                                                rules={[
                                                    { required: true, type: 'email' },
                                                    {
                                                        whitespace: false,
                                                        message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Input type="email" className="text-fit-green-900" disabled={readOnly} />
                                            </Form.Item>

                                            <Form.Item
                                                label={<span className="text-fit-green-900 font-semibold"> {displayMessage('USER_TYPE')}:</span>}
                                                name="type"
                                                className={`w-full m-0`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: displayMessage('USER_TYPE_MANDATORY'),
                                                    },
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <Select disabled={readOnly}>
                                                    {userTypeList.map((option) => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            flexDirection: mobile_mode ? 'column' : 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: mobile_mode ? '100%' : 300,
                                                justifyContent: mobile_mode ? 'space-between' : 'flex-start',
                                                flexDirection: 'row',
                                                marginTop: mobile_mode ? 20 : 0,
                                            }}
                                        >
                                            <Button style={{ minWidth: 80, marginTop: 15 }} type={'primary'} onClick={onOpenEdit}>
                                                {readOnly ? displayMessage('EDIT') : displayMessage('SAVE_EDIT')}
                                            </Button>
                                            {!readOnly ? (
                                                <Button
                                                    style={{
                                                        minWidth: 80,
                                                        marginTop: 15,
                                                        marginLeft: mobile_mode ? 0 : 10,
                                                    }}
                                                    type={'primary'}
                                                    onClick={onCloseEdit}
                                                    danger
                                                >
                                                    {displayMessage('CLOSE')}
                                                </Button>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    )
}
