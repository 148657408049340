import React, { useState, useContext, useEffect } from 'react'

import { Form, Input, Tabs } from 'antd'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import styles from '../../../services/styles'
import { SL_Button } from '../../../components/buttons/Button'
import { CloseCircleOutlined, EditTwoTone } from '@ant-design/icons'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { UpdatePassword } from './UpdatePassword'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const { tc1 } = styles

const inputsClassName = `border-appian-blue max-h-9 text-base`

export function DetailProfile({}) {
    const { mobile_mode } = useContext(GeneralContext)

    const [userData, setUserData] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [changingPassword, setChangingPassword] = useState(false)
    const [formRef] = Form.useForm()
    const [formRefPassword] = Form.useForm()

    const { setShowLoading, showAlert } = useContext(GeneralContext)

    const { loggedUser } = useContext(AuthContext)

    useEffect(() => {
        //tela tbm é o detalhe do usuario logado
        loadUserData(loggedUser.id)
    }, [])

    const loadUserData = async (id) => {
        const { data } = await api.user.find(id)
        setUserData(data)
        formRef.setFieldsValue(data)
    }

    const saveUpdateUser = (values) => {
        setShowLoading(true)
        const id = userData.id
        const payload = { ...values }
        api.user
            .update(id, payload)
            .then((rs) => {
                showAlert('UPDATE_USER_SUCCESS', EAlertTypes.SUCCESS)
                setUserData(rs.data)
                setReadOnly(true)
            })
            .finally(() => {
                setShowLoading(false)
            })
    }

    const saveUpdatePassword = (values) => {
        setShowLoading(true)
        const id = userData.id
        const payload = { ...values }
        api.auth
            .changePassword(id, payload)
            .then((rs) => {
                showAlert('UPDATE_USER_SUCCESS', EAlertTypes.SUCCESS)
                setUserData(rs.data)
                setChangingPassword(false)
            })
            .finally(() => {
                setShowLoading(false)
            })
    }

    const handleOnEdit = () => {
        if (readOnly) {
            setReadOnly((current) => !current)
        } else {
            formRef.submit()
        }
    }
    const handleOnChangePassword = () => {
        if (!changingPassword) {
            setChangingPassword((current) => !current)
        } else {
            formRefPassword.submit()
        }
    }

    return (
        <div className={`w-full flex flex-col gap-2`}>
            <div className="font-bold text-lg text-appian-blue text-center">{displayMessage('PERSONAL_DATA')}</div>
            <Form
                className="w-full flex flex-col p-5"
                style={{ maxWidth: mobile_mode ? '80%' : '50%' }}
                onFinish={saveUpdateUser}
                layout="vertical"
                requiredMark="optional"
                initialValues={userData}
                disabled={readOnly}
                form={formRef}
            >
                <Form.Item label={displayMessage('FIRST_NAME')} name="first_name" className={`w-full flex-col`} rules={[{ required: true, message: displayMessage('FULL_NAME_MANDATORY') }]}>
                    <Input type="text" size="large" placeholder={displayMessage('FIRST_NAME')} className={inputsClassName} />
                </Form.Item>
                <Form.Item label={displayMessage('LAST_NAME')} name="last_name" className={`w-full flex-col`} rules={[{ required: false, message: displayMessage('FULL_NAME_MANDATORY') }]}>
                    <Input type="text" size="large" placeholder={displayMessage('LAST_NAME')} className={inputsClassName} />
                </Form.Item>

                <div className="flex flex-col">
                    <Form.Item
                        label={displayMessage('EMAIL')}
                        name="email"
                        className={`w-full flex-col`}
                        rules={[
                            {
                                required: false,
                                type: 'email',
                                message: displayMessage('INVALID_EMAIL'),
                            },
                        ]}
                    >
                        <Input type="text" size="large" placeholder={displayMessage('EMAIL')} className={inputsClassName} disabled />
                    </Form.Item>
                </div>
            </Form>
            <div className="flex w-full justify-end gap-2">
                {!readOnly && (
                    <SL_Button
                        icon={<CloseCircleOutlined color={tc1} />}
                        size="large"
                        onClick={() => setReadOnly(true)}
                        className="border-appian-grey text-appian-blue bg-appian-grey max-h-9 text-base align self-end"
                    >
                        {displayMessage('CANCEL')}
                    </SL_Button>
                )}
                <SL_Button icon={<EditTwoTone twoToneColor="white" />} size="large" onClick={handleOnEdit} className="border-appian-blue max-h-9 text-base align self-end">
                    {readOnly ? displayMessage('EDIT') : displayMessage('SAVE')}
                </SL_Button>
            </div>

            <Tabs style={{ marginTop: '20Px' }} />

            <div className="font-bold text-lg text-appian-blue text-center">{displayMessage('CHANGE_PASSWORD')}</div>

            <Form
                className="w-full flex flex-col p-5"
                style={{ maxWidth: mobile_mode ? '80%' : '50%' }}
                onFinish={saveUpdatePassword}
                layout="vertical"
                requiredMark="optional"
                initialValues={userData}
                disabled={!changingPassword}
                form={formRefPassword}
            >
                <UpdatePassword />
            </Form>

            <div className="flex w-full justify-end gap-2">
                {changingPassword && (
                    <SL_Button
                        icon={<CloseCircleOutlined color={tc1} />}
                        size="large"
                        onClick={() => setChangingPassword(false)}
                        className="border-appian-grey text-appian-blue bg-appian-grey max-h-9 text-base align self-end"
                    >
                        {displayMessage('CANCEL')}
                    </SL_Button>
                )}
                <SL_Button icon={<EditTwoTone twoToneColor="white" />} size="large" onClick={handleOnChangePassword} className="border-appian-blue max-h-9 text-base align self-end">
                    {!changingPassword ? displayMessage('REDEFINE_PASSWORD') : displayMessage('SAVE')}
                </SL_Button>
            </div>
        </div>
    )
}
