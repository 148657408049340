import React, { useContext } from 'react'
import { Button, Input, Form, Select } from 'antd'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { SL_AutoCompleteSelect } from '../../../components/selects/SL_AutoCompleteSelect'
import api from '../../../services/api'
import { useState } from 'react'

const { Option } = Select

export default function ClientFilters({ handleOnSearchClients, filterHeight }) {
    const [form] = Form.useForm()
    const [value, setValue] = useState([])

    const { mobile_mode, windowWidth } = useContext(GeneralContext)

    const handleOnClearFilter = () => {
        form.resetFields()
    }

    const onSubmitForm = (values) => {
        handleOnSearchClients(values)
    }

    async function fetchClientList(value) {
        const something = (await api.client.list({ by_company_name: value })).data
        const options = []
        something.map((client) => {
            let option = {
                label: client.company_name,
                value: client.id,
            }
            options.push(option)
        })
        return options
    }

    const windowBreakpoint = windowWidth < 800 //px
    const windowBreakpoint1400 = windowWidth < 1400 //px
    const windowBreakpoint1650 = windowWidth < 1650 //px

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 0,
                height: mobile_mode ? '' : filterHeight + 'vh',
                minHeight: mobile_mode ? '250px' : '',
                color: 'white',
                overflow: 'hidden',
                border: '1px solid lightgrey',
            }}
        >
            <div className="flex justify-between p-2 ">
                <span className="self-center font-bold text-lg flex-1 text-center text-black">{displayMessage('SEARCH_FILTERS')}</span>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
                className={'bg-fit-gray-100'}
            >
                <Form form={form} className={`flex flex-1 flex-col ss:flex-row min-w-fit gap-4 flex-wrap ${mobile_mode ? 'justify-center' : ''}`} layout="inline" onFinish={onSubmitForm}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: mobile_mode || windowBreakpoint1650 ? 'column' : 'row',
                            justifyContent: 'start',
                            width: '99%',
                            marginLeft: mobile_mode ? 0 : 5,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                marginTop: 20,
                                marginLeft: 5,
                                marginRight: 5,
                                marginBottom: 10,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: mobile_mode || windowBreakpoint1400 ? 'column' : 'row',
                                    gap: 5,
                                }}
                            >
                                <div
                                    className="flex gap-2 items-center"
                                    style={{
                                        display: 'flex',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <span className="font-bold text-fit-green-900 ss:w-[130px]">{displayMessage('COMPANY_NAME')}:</span>
                                    <Form.Item className="m-0 flex-1" name={'by_company'} label={''}>
                                        <SL_AutoCompleteSelect
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue)
                                            }}
                                            placeholder={displayMessage('CLIENT_SELECT')}
                                            fetchOptions={fetchClientList}
                                        ></SL_AutoCompleteSelect>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: 8,
                        alignItems: 'flex-end',
                        justifyContent: windowBreakpoint ? 'center' : 'flex-end',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: windowBreakpoint ? 'center' : 'flex-end',
                            paddingLeft: !windowBreakpoint && 8,
                            alignItems: 'flex-end',
                            borderLeft: !windowBreakpoint && '1px solid lightgrey',
                            gap: 8,
                        }}
                    >
                        <Button onClick={() => form.submit()} type="primary" style={{ width: 200, fontWeight: 'bold', borderRadius: 7 }}>
                            {displayMessage('SEARCH')}
                        </Button>

                        <Button style={{ width: 200, fontWeight: 'bold', borderRadius: 7 }} type="primary" danger={true} onClick={handleOnClearFilter}>
                            {displayMessage('CLEAN_FILTERS')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
