import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { medical_exams_column } from './grid_columns/medical_exams_column'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { Button } from 'antd'
import { CloseOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons'
import { colors } from '../../services/styles'
import { displayMessage } from '../../helpers/messages'
import { SL_Button } from '../../components/buttons/Button'
import { EAlertTypes } from '../../enums/EAlertTypes'

export const ListMedicalExams = () => {
    const [exams, setExams] = useState([])
    const [isGridLoading, setIsGridLoading] = useState(true)
    const [isFiltersVisible, setIsFilterVisible] = useState(false)
    const [currentExam, setCurrentExam] = useState(null)
    const apiRef = useGridApiRef()
    const { showAlert, mobile_mode } = useContext(GeneralContext)
    const navigate = useNavigate()

    useEffect(() => {
        loadMedicalExams()
    }, [])

    async function loadMedicalExams() {
        try {
            const { data } = await api.medicalExams.list()
            setExams(data)
        } catch (error) {
            showAlert('LOAD_EXAMS_ERROR')
        } finally {
            setIsGridLoading(false)
        }
    }

    const handleRowChange = (e) => {
        setCurrentExam(e?.row)
    }

    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    const handleOnGoToDetailExam = () => {
        console.log('currentExam', currentExam)
        if(!currentExam) { return showAlert('SELECT_EXAM_ERROR', EAlertTypes.WARNING)}
        navigate('' + currentExam.id)
        
    }

    return (
        <>
            <div className="flex flex-col flex-1 h-full">
                <div style={{ background: colors.primary[900] }} className={`flex p-2 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col items-center gap-4 flex-wrap' : 'gap-2'}`}>
                    <div className="self-center text-white font-semibold text-base">{displayMessage('LIST_EXAMS')}</div>
                    <div className={'flex flex-1 justify-end gap-2 flex-wrap'}>
                    <SL_Button
                            type="default"
                            className={`flex items-center flex-1 ${mobile_mode ? 'w-[200px]' : 'max-w-[200px]'}`}
                            icon={<EditOutlined className="text-base flex items-center" />}
                            onClick={handleOnGoToDetailExam}
                            // disabled={!currentUser}
                        >
                            <span className="text-fit-green-800 font-semibold flex-1">{displayMessage('EXAM_DETAIL')}</span>
                        </SL_Button>

                        {/* <Button
                            type="default"
                            shape="circle"
                            onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                            icon={
                                !isFiltersVisible ? (
                                    <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                ) : (
                                    <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                )
                            }
                        ></Button> */}
                    </div>
                </div>
                {isFiltersVisible ? <ExamFilters handleOnSearchExams={loadMedicalExams} filterHeight={'20vh'} /> : <div />}
                <div className={`w-full flex-1 min-h-[400px] smb:min-h-0 mb-[20px]`}>
                    <SL_Grid rows={exams} columns={medical_exams_column} loading={isGridLoading} onRowClick={handleRowChange} apiRef={apiRef} />
                </div>
            </div>
        </>
    )
}