import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { Link, useMatches, useParams } from 'react-router-dom'

export function OldBreadcrumb({ listCrumbs = [], children }) {
    const pathParams = useParams()

    return (
        <>
            <AntdBreadcrumb style={{ marginBottom: 10 }}>
                <AntdBreadcrumb.Item key={'home'}>
                    <Link to={'/dashboard'}>
                        <HomeOutlined />
                    </Link>
                </AntdBreadcrumb.Item>

                {listCrumbs?.map(({ label, link, params = [] }, index) => {
                    let formatedLink = link
                    let formatedLabel = label
                    if (params?.length > 0) {
                        for (let index = 0; index < params.length; index++) {
                            const param = params[index]

                            if (link) {
                                formatedLink = formatedLink.replace(`{${param}}`, pathParams[param])
                            }

                            formatedLabel = formatedLabel.replace(`{${param}}`, pathParams[param])
                        }
                    }

                    return <AntdBreadcrumb.Item key={index}>{link ? <Link to={formatedLink}>{formatedLabel}</Link> : formatedLabel}</AntdBreadcrumb.Item>
                })}
            </AntdBreadcrumb>

            {children}
        </>
    )
}

export function Breadcrumb({ children }) {
    const matches = useMatches()

    return (
        <>
            <AntdBreadcrumb style={{ marginBottom: 10 }}>
                <AntdBreadcrumb.Item key={'home'}>
                    <Link to={'/clients'}>
                        <HomeOutlined />
                    </Link>
                </AntdBreadcrumb.Item>

                {matches
                    .filter((e) => e.handle?.crumb)
                    .map(({ pathname, handle: { crumb } }, index) => {
                        return (
                            <AntdBreadcrumb.Item key={index}>
                                <Link to={pathname}>{crumb?.title ?? ''}</Link>
                            </AntdBreadcrumb.Item>
                        )
                    })}
            </AntdBreadcrumb>

            {children}
        </>
    )
}
