import { Modal } from 'antd'
import { useState } from 'react'
import { SL_Button } from '../../../components/buttons/Button'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/styles'
import { Spin } from 'antd'

export function ForgotPasswordModal({ onOk, onClose, showLoading }) {
    const [email, setEmail] = useState('')

    const handleClosePasswordRecoveryModal = () => {
        onClose()
        setEmail(null)
    }

    return (
        <>
            <Modal
                title={<div style={{ color: colors['primary'][900] }}>{displayMessage('RECOVER_PASSWORD')}</div>}
                open={true}
                onCancel={handleClosePasswordRecoveryModal}
                centered={true}
                footer={
                    !showLoading && (
                        <div className="flex flex-col ss:flex-row gap-2 justify-end">
                            <SL_Button variant={'close'} className="w-full ss:w-[150px] font-bold" onClick={handleClosePasswordRecoveryModal}>
                                {displayMessage('CLOSE')}
                            </SL_Button>

                            <SL_Button
                                className="w-full m-0 ss:w-[150px] font-bold"
                                onClick={() => {
                                    onOk(email)
                                }}
                            >
                                {displayMessage('CONFIRM')}
                            </SL_Button>
                        </div>
                    )
                }
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: showLoading ? 114 : 70,
                    }}
                >
                    {showLoading ? (
                        <div>
                            <Spin tip="Aguarde..." />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: 13,
                                flex: 1,
                                color: colors['primary'][900],
                            }}
                        >
                            <div style={{ fontWeight: 'bold', marginBottom: 5 }}>{displayMessage('DISPLAY_REGISTERED_EMAIL')}:</div>

                            <SL_Input
                                type={'email'}
                                value={email}
                                required={true}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}
