import React, { useContext, useState, useEffect } from 'react'

import api from '../../../services/api'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import styles, { colors } from '../../../services/styles'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import { access_keys_grid_column } from '../grid_columns/access_keys_grid_column'
import { CreateAccessKeyModal } from './CreateAccessKeyModal'
import { DetailAccessKeyModal } from './DetailAccessKeyModal'

export function ListAcessKeys({ client }) {
    const [clientAccessKeys, setClientAccessKeys] = useState([])
    const [selectedClientAccessKeys, setSelectedClientAccessKeys] = useState(null)
    const [isLoadingAccessKeys, setIsLoadingAccessKeys] = useState(true)
    const [isModalAccessKeyCreationOpen, setIsModalAccessKeyCreationOpen] = useState(false)
    const [isModalAccessKeyDetailOpen, setIsModalAccessKeyDetailOpen] = useState(false)

    const { mobile_mode } = useContext(GeneralContext)

    useEffect(() => {
        loadAccessKeysFromClientLoading()
    }, [])

    async function loadAccessKeysFromClient(changeSelectedKey = false) {
        const { data } = await api.clientAccessKeys.listByClient(client.id)
        setClientAccessKeys(data)

        if (changeSelectedKey) {
            const selectedKeyNewValue = data.find((e) => e.id == selectedClientAccessKeys.id)
            setSelectedClientAccessKeys(selectedKeyNewValue)
        }
    }

    async function loadAccessKeysFromClientLoading() {
        try {
            await loadAccessKeysFromClient()
        } catch (error) {
        } finally {
            setIsLoadingAccessKeys(false)
        }
    }

    function onOpenAccessKeyCreationModal() {
        setIsModalAccessKeyCreationOpen(true)
    }

    function onCloseAccessKeyCreationModal() {
        setIsModalAccessKeyCreationOpen(false)
    }

    function onOpenAccessKeyDetailModal() {
        if (selectedClientAccessKeys == null) return
        setIsModalAccessKeyDetailOpen(true)
    }

    function onCloseAccessKeyDetailModal() {
        setIsModalAccessKeyDetailOpen(false)
    }

    function onSelectAccessKey(e) {
        setSelectedClientAccessKeys(e?.row)
    }

    return (
        <>
            {isModalAccessKeyCreationOpen && <CreateAccessKeyModal onLoadClientAccessKeys={loadAccessKeysFromClient} client={client} onClose={onCloseAccessKeyCreationModal} />}
            {isModalAccessKeyDetailOpen && <DetailAccessKeyModal onLoadClientAccessKeys={loadAccessKeysFromClient} accessKeyData={selectedClientAccessKeys} onClose={onCloseAccessKeyDetailModal} />}

            <div className="h-full flex flex-col">
                <div className={`flex w-full bg-fit-green-700 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col items-center gap-4' : 'gap-2'}`}>
                    <div className={`flex-1 flex flex-wrap gap-2 rounded-t-md p-2 w-full`} style={{ background: colors.primary[900] }}>
                        <div className={'flex self-center'}>
                            <div className="self-center text-white font-semibold text-base">{displayMessage('LIST_ACCESS_KEYS')}</div>
                        </div>

                        <div className="flex-1 flex items-end justify-end gap-2 flex-col smb:flex-row">
                            <SL_Button
                                type="default"
                                className={`flex items-center flex-1 w-full ${mobile_mode ? 'ss:w-[250px]' : 'max-w-[250px]'}`}
                                onClick={onOpenAccessKeyDetailModal}
                                disabled={selectedClientAccessKeys == null}
                                icon={<EditOutlined className="text-fit-blue-800 text-base flex items-center" />}
                            >
                                <span className="text-fit-blue-800 font-semibold flex-1">{displayMessage('DETAIL_ACCESS_KEY')}</span>
                            </SL_Button>

                            <SL_Button
                                type="default"
                                className={`flex items-center flex-1 w-full ${mobile_mode ? 'ss:w-[250px]' : 'max-w-[250px]'}`}
                                onClick={onOpenAccessKeyCreationModal}
                                icon={<PlusOutlined className="text-fit-blue-800 text-base flex items-center" />}
                            >
                                <span className="text-fit-blue-800 font-semibold flex-1">{displayMessage('CREATE_ACCESS_KEY')}</span>
                            </SL_Button>
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%', flex: 1 }}>
                    <SL_Grid
                        rows={clientAccessKeys}
                        // apiRef={gridRef}
                        onRowClick={onSelectAccessKey}
                        columns={access_keys_grid_column}
                        style={{ marginBottom: 50 }}
                        loading={isLoadingAccessKeys}
                    />
                </div>
            </div>
        </>
    )
}
