import React, { useContext } from 'react'
import { Button, Input, Form, Select, DatePicker } from 'antd'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { SL_AutoCompleteSelect } from '../../../components/selects/SL_AutoCompleteSelect'
import api from '../../../services/api'
import { useState } from 'react'
import { DateTime } from 'luxon'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

const { Option } = Select

export default function EventFilters({ handleOnSearchClients, filterHeight, enumEventTypes, today, monthAgo, examOptions }) {
    const [form] = Form.useForm()
    const [value, setValue] = useState([])

    dayjs.extend(customParseFormat)
    const dateFormat = 'dd-MM-yyyy'
    const dateFormatDayJS = 'DD-MM-YYYY'
    const formattedMonthAgo = DateTime.fromJSDate(monthAgo).toFormat(dateFormat)
    const formattedToday = DateTime.fromJSDate(today).toFormat(dateFormat)

    const { mobile_mode, windowWidth } = useContext(GeneralContext)

    const handleOnClearFilter = () => {
        form.resetFields()
        form.submit()
    }

    const onSubmitForm = (values) => {
        handleOnSearchClients(values)
    }

    const windowBreakpoint = windowWidth < 800 //px
    const windowBreakpoint1400 = windowWidth < 1400 //px
    const windowBreakpoint1650 = windowWidth < 1650 //px

    async function fetchClientList(value) {
        const something = (await api.client.list({ by_company_name: value })).data
        const options = []
        something.map((client) => {
            let option = {
                label: client.company_name,
                value: client.id,
            }
            options.push(option)
        })
        return options
    }

    function onClickAllExams() {
        const examsValues = examOptions.map((e) => e.value)
        form.setFieldValue('exams_codes', examsValues)
        form.submit()
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 0,
                height: mobile_mode ? '' : filterHeight + 'vh',
                minHeight: mobile_mode ? '400px' : '',
                color: 'white',
                overflow: 'hidden',
                border: '1px solid lightgrey',
            }}
        >
            <div className="flex flex-1 justify-between mt-2 ">
                <span className="self-center font-bold text-lg flex-1 text-center text-black">{displayMessage('SEARCH_FILTERS')}</span>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
                className={'bg-fit-gray-100'}
            >
                <Form form={form} className={`flex flex-1 flex-col ss:flex-row min-w-fit gap-4 flex-wrap ${mobile_mode ? 'justify-center' : ''}`} layout="inline" onFinish={onSubmitForm}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: mobile_mode || windowBreakpoint1650 ? 'column' : 'row',
                            justifyContent: 'start',
                            width: '100%',
                            marginLeft: mobile_mode ? 0 : 5,
                            paddingRight: 3,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                marginTop: 15,
                                marginLeft: 5,
                                marginRight: 5,
                                marginBottom: 10,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: mobile_mode || windowBreakpoint1400 ? 'column' : 'row',
                                    gap: windowBreakpoint1400 ? 2 : 15,
                                    flexWrap: 'wrap',
                                }}
                            >
                                <div
                                    className="flex items-center"
                                    style={{
                                        display: 'flex',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        gap: windowBreakpoint1400 ? 4 : 0,
                                    }}
                                >
                                    <span className="font-bold text-fit-green-900 ss:w-[130px]">{displayMessage('EVENT_TYPE')}:</span>
                                    <Form.Item initialValue={''} className="m-0 flex-1" name={'by_event_type'} label={''}>
                                        <Select className={`w-[200px] ${mobile_mode ? '' : 'ss:'}w-[170px]`} allowClear>
                                            {Object.values(enumEventTypes).map((event) => (
                                                <Option key={event.code} value={event.code}>
                                                    {event.type}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div
                                    className="flex items-center"
                                    style={{
                                        display: 'flex',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        gap: windowBreakpoint1400 ? 4 : 10,
                                    }}
                                >
                                    <span className={`font-bold text-fit-green-900 ${windowBreakpoint1400 ? 'ss:w-[130px]' : ''} `}>{displayMessage('USER')}:</span>
                                    <Form.Item initialValue={''} className="m-0 flex-1" name={'by_person'} label={''}>
                                        <Input className="ss:w-[200px] flex-1" />
                                    </Form.Item>
                                </div>
                                <div
                                    className="flex items-center"
                                    style={{
                                        display: 'flex',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        gap: windowBreakpoint1400 ? 4 : 10,
                                    }}
                                >
                                    <span className={`font-bold text-fit-green-900 ${windowBreakpoint1400 ? 'ss:w-[130px]' : ''} `}>{displayMessage('CLIENT')}:</span>
                                    <Form.Item className="m-0 flex-1" name={'by_client'} label={''}>
                                        <SL_AutoCompleteSelect
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue)
                                            }}
                                            placeholder={displayMessage('CLIENT_SELECT')}
                                            fetchOptions={fetchClientList}
                                        ></SL_AutoCompleteSelect>
                                    </Form.Item>
                                </div>
                                <div
                                    className="flex items-center"
                                    style={{
                                        display: 'flex',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        gap: windowBreakpoint1400 ? 4 : 10,
                                    }}
                                >
                                    <span className={`font-bold text-fit-green-900 ${windowBreakpoint1400 ? 'ss:w-[130px]' : ''} `}>{displayMessage('DATE')}:</span>
                                    <Form.Item initialValue={dayjs(formattedMonthAgo, dateFormatDayJS)} className="m-0 flex" name={'start_date'} label={''}>
                                        <DatePicker className="ss:w-[200px]" placeholder={displayMessage('FROM_THIS_DATE')} format={dateFormatDayJS}></DatePicker>
                                    </Form.Item>

                                    <Form.Item initialValue={dayjs(formattedToday, dateFormatDayJS)} className="m-0 flex-1" name={'end_date'} label={''}>
                                        <DatePicker className="ss:w-[200px] flex-1" placeholder={displayMessage('TO_THIS_DATE')} format={dateFormatDayJS}></DatePicker>
                                    </Form.Item>
                                </div>
                                <div
                                    className="flex items-center w-full"
                                    style={{
                                        display: 'flex ',
                                        flexDirection: mobile_mode ? 'column' : 'row',
                                        gap: windowBreakpoint1400 ? 4 : 10,
                                    }}
                                >
                                    <span className={`font-bold text-fit-green-900 ${windowBreakpoint1400 ? 'ss:w-[130px]' : ''} `}>{displayMessage('EXAMS')}:</span>
                                    <Form.Item initialValue={[]} className="m-0 flex-1 w-full" name={'exams_codes'} label={''}>
                                        <Select
                                            className={`${mobile_mode ? '' : 'ss:'} w-full`}
                                            allowClear
                                            mode="multiple"
                                            options={examOptions}
                                            filterOption={(input, option) => option.label.includes(input)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: 8,
                        alignItems: 'flex-end',
                        justifyContent: windowBreakpoint ? 'center' : 'flex-end',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: windowBreakpoint ? 'center' : 'flex-end',
                            paddingLeft: !windowBreakpoint && 8,
                            alignItems: 'flex-end',
                            borderLeft: !windowBreakpoint && '1px solid lightgrey',
                            gap: 8,
                        }}
                    >
                        <Button
                            onClick={() => form.submit()}
                            type="primary"
                            style={{
                                width: mobile_mode ? 140 : 200,
                                fontWeight: 'bold',
                                borderRadius: 7,
                            }}
                        >
                            {displayMessage('SEARCH')}
                        </Button>

                        <Button
                            onClick={onClickAllExams}
                            type="primary"
                            style={{
                                width: mobile_mode ? 140 : 200,
                                fontWeight: 'bold',
                                borderRadius: 7,
                            }}
                        >
                            {displayMessage('SELECT_ALL_EXAMS')}
                        </Button>

                        <Button
                            style={{
                                width: mobile_mode ? 140 : 200,
                                fontWeight: 'bold',
                                borderRadius: 7,
                            }}
                            type="primary"
                            danger={true}
                            onClick={handleOnClearFilter}
                        >
                            {displayMessage('CLEAN_FILTERS')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
