import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Button } from '../../components/buttons/Button'
import { SL_Input } from '../../components/input/Input'
import { ForgotPasswordModal } from './components/ForgotPasswordModal'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { EAlertTypes } from '../../enums/EAlertTypes'

export const Login = () => {
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const { setLoggedUser, setAuthorizationToken } = useContext(AuthContext)

    const navigate = useNavigate()

    const { showAlert } = useContext(GeneralContext)

    const onFinish = async ({ email, password }) => {
        try {
            setShowLoading(true)
            const { data } = await api.auth.authenticate({ email, password })
            const { user, token } = data

            setAuthorizationToken(token, true)
            setLoggedUser(user)

            navigate('/clients')
        } catch (err) {
            console.log(err)
        } finally {
            setShowLoading(false)
        }
    }

    const handleOkPasswordRecovery = (email) => {
        if (!email) {
            showAlert('INVALID_EMAIL')
            return
        }

        setShowLoading(true)

        api.auth
            .forgotPassword(email)
            .then((rs) => {
                setTimeout(() => {
                    onCloseModalForgotPassword()

                    setShowLoading(false)

                    if (rs.status == 200) {
                        showAlert('FORGOT_PASSWORD_SUCCESS', EAlertTypes.SUCCESS)
                    }
                }, 500)
            })
            .catch(() => {
                setTimeout(() => {
                    setShowLoading(false)
                }, 500)
            })
    }

    const onCloseModalForgotPassword = () => {
        if (showLoading) {
            return
        }

        setIsForgotPasswordModalOpen(false)
    }

    return (
        <>
            {isForgotPasswordModalOpen && <ForgotPasswordModal onOk={handleOkPasswordRecovery} onClose={onCloseModalForgotPassword} showLoading={showLoading} />}

            <>
                <div className="text-sl-gray-50 font-bold mb-4 text-xl ss:text-2xl shadow-title">{displayMessage('LOGIN_TITLE')}</div>

                <Form onFinish={onFinish} className="max-w-[400px] w-full" layout="vertical" requiredMark={false}>
                    <div className="flex flex-col gap-1">
                        <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('EMAIL')}</div>

                        <Form.Item name="email" rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]} className="mb-4">
                            <SL_Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={displayMessage('ENTER_EMAIL')} className={'w-full font-bold shadow-md'} />
                        </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1 mt-1">
                        <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('PASSWORD')}</div>

                        <Form.Item name="password" rules={[{ required: true, message: displayMessage('DISPLAY_PASSWORD') }]} className="mb-6">
                            <SL_Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                className={'w-full font-bold shadow-md'}
                                placeholder={displayMessage('ENTER_PASSWORD')}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item hasFeedback className="mb-3 mt-2">
                        <SL_Button type="primary" className={'w-full font-bold shadow-md'} htmlType="submit">
                            {displayMessage('ENTER')}
                        </SL_Button>
                    </Form.Item>

                    <Form.Item className="m-0">
                        <div className="flex justify-center">
                            <SL_Button
                                className={'text-gray-50 font-bold shadow-title'}
                                type="link"
                                onClick={() => {
                                    setIsForgotPasswordModalOpen(true)
                                }}
                            >
                                {displayMessage('RECOVER_PASSWORD')}
                            </SL_Button>
                        </div>
                    </Form.Item>
                </Form>
            </>
        </>
    )
}
