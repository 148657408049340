import { displayMessage } from '../../../helpers/messages'

export const user_list_grid_column = [
    {
        field: 'id',
        headerName: displayMessage('CODE'),
        width: 100,
    },
    {
        field: 'name',
        headerName: displayMessage('NAME'),
        valueGetter: (params) => {
            return params?.row?.name || ''
        },
        width: 250,
    },
    {
        field: 'email',
        headerName: displayMessage('EMAIL'),
        flex: 1,
        valueGetter: (params) => {
            return params?.row?.email || 0
        },
    },
    {
        field: 'registry_sub',
        headerName: displayMessage('REGISTRY_SUB'),
        width: 250,
        valueGetter: (params) => {
            return params?.row?.registry_sub || 0
        },
    },
]
