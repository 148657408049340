import { displayMessage } from '../../../helpers/messages'

export const medical_exams_column = [
    {
        field: 'first_name',
        headerName: displayMessage('NAME'),
        valueGetter: (params) => {
            return params?.row?.param || ''
        },
        width: 250,
    },
    
    {
        field: 'reference_method',
        headerName: displayMessage('REFERENCE_METHOD'),
        valueGetter: (params) => {
            return params?.row?.reference_method || ''
        },
        flex: 1,
    },
    {
        field: 'unit',
        headerName: displayMessage('UNIT'),
        valueGetter: (params) => {
            return params?.row?.unit || ''
        },
        flex: 1,
    },
    {
        field: 'male_reference',
        headerName: displayMessage('MALE_REFERENCE'),
        valueGetter: (params) => {
            return params?.row?.male_reference_value || ''
        },
        flex: 1,
    },
    {
        field: 'female_reference',
        headerName: displayMessage('FEMALE_REFERENCE'),
        valueGetter: (params) => {
            return params?.row?.female_reference_value || ''
        },
        flex: 1,
    },
    
]
