import { displayMessage } from '../../../helpers/messages'
import { Form, Input } from 'antd'
import React from 'react'

const inputsClassName = `border-appian-blue max-h-9 text-base`

export function UpdatePassword({}) {
    return (
        <>
            <Form.Item label={displayMessage('OLD_PASSWORD')} name="oldPassword" className={`flex-1`} rules={[{ required: true, message: displayMessage('PASSWORD_MANDATORY') }]}>
                <Input type="password" size="large" placeholder={displayMessage('OLD_PASSWORD')} className={inputsClassName} />
            </Form.Item>

            <Form.Item label={displayMessage('NEW_PASSWORD')} name="newPassword" className={`flex-1`} rules={[{ required: true, message: displayMessage('PASSWORD_MANDATORY') }]}>
                <Input type="password" size="large" placeholder={displayMessage('NEW_PASSWORD')} className={inputsClassName} autoComplete="new-password" />
            </Form.Item>

            <Form.Item
                label={displayMessage('CONFIRM_NEW_PASSWORD')}
                name="confirmedPassword"
                className={`flex-1`}
                dependencies={['email']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: displayMessage('PASSWORD_MANDATORY'),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve()
                            }

                            return Promise.reject(new Error(displayMessage('INVALID_CONFIRMED_PASSWORD')))
                        },
                    }),
                ]}
            >
                <Input type="password" size="large" placeholder={displayMessage('CONFIRM_NEW_PASSWORD')} className={inputsClassName} />
            </Form.Item>
        </>
    )
}
