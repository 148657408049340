import React, { useContext, useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import api from '../../../services/api'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EditOutlined, FilterOutlined, CloseOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import styles, { colors } from '../../../services/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Popconfirm } from 'antd'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import { user_list_grid_column } from '../grid_columns/user_list_grid_column'
import PersonFiltersToClientScreen from './PersonFiltersToClientScreen'
import CreatePerson from './CreatePerson'

const { tc1, tc2, bc2, bc6 } = styles

export default function ListPeopleFromClient({ client }) {
    const [isModalCreatePersonVisible, setIsModalCreatePersonVisible] = useState(false)
    const [people, setPeople] = useState([])
    const [isFiltersVisible, setIsFilterVisible] = useState(false)
    const [currentPerson, setCurrentPerson] = useState(null)
    const [clientsList, setClientsList] = useState(null)
    const { clientId } = useParams()
    const navigate = useNavigate()

    const gridRef = useGridApiRef()
    const { setShowLoading, mobile_mode } = useContext(GeneralContext)

    useEffect(() => {
        if (client?.id) {
            loadPeopleFromClientDataLoad()
            loadPeopleFromClientData()
        } else {
            setPeople([])
        }
    }, [client])

    const loadPeopleFromClientDataLoad = async () => {
        setShowLoading(true)
        try {
            let tempClientList = await api.client.list()
            setClientsList(tempClientList.data)
            loadPeopleFromClientData()
            setCurrentPerson(null)
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoading(false)
        }
    }

    async function loadPeopleFromClientData(values) {
        try {
            const id = client.id
            const rs = await api.people.findByClient(id, values)
            gridRef.current.setSelectionModel([])
            setPeople(rs.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleGoToUserDetail = () => {
        navigate('' + `people/${currentPerson?.id}`)
    }

    const onDeleteUser = async (id) => {
        try {
            const { data } = await api.people.delete(id)
            loadPeopleFromClientDataLoad()
        } catch (error) {
            console.log(error)
        }
    }
    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    const handleOnOpenModalCreatePerson = () => {
        setIsModalCreatePersonVisible(true)
    }
    const handleOnCloseModalCreatePerson = () => {
        setIsModalCreatePersonVisible(false)
    }

    function onRowClickGridUser(e) {
        setCurrentPerson(e?.row)
    }

    return (
        <>
            {isModalCreatePersonVisible && (
                <CreatePerson handleOnCloseModal={handleOnCloseModalCreatePerson} refreshPeople={loadPeopleFromClientDataLoad} clientsList={clientsList} clientId={clientId} />
            )}

            <div className="h-full flex flex-col">
                <div className={`flex w-full bg-fit-green-700 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'w-full flex-col items-center gap-4' : 'gap-2'}`}>
                    <div className={`flex-1 flex flex-wrap gap-2 rounded-t-md p-2 w-full flex-col smb:flex-row`} style={{ background: colors.primary[900] }}>
                        <div className={'flex self-center'}>
                            <div className="self-center text-white font-semibold text-base">{displayMessage('LIST_USER')}</div>
                        </div>

                        <div className="flex-1 flex justify-end gap-2">
                            <SL_Button
                                type="default"
                                className={`flex items-center flex-1 sm:max-w-[200px]`}
                                onClick={handleGoToUserDetail}
                                icon={<EditOutlined className="text-fit-blue-800 text-base flex items-center" />}
                                disabled={!currentPerson}
                            >
                                <span className="text-fit-blue-800 font-semibold flex-1">{displayMessage('USER_DETAIL')}</span>
                            </SL_Button>

                            <Button
                                type="default"
                                shape="circle"
                                onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                                icon={
                                    !isFiltersVisible ? (
                                        <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    ) : (
                                        <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    )
                                }
                            ></Button>
                        </div>
                    </div>
                </div>

                {isFiltersVisible ? <PersonFiltersToClientScreen handleOnSearchPeople={loadPeopleFromClientData} filterHeight={'20vh'} /> : <div />}

                <div style={{ width: '100%', flex: 1 }}>
                    <SL_Grid rows={people ?? []} apiRef={gridRef} columns={user_list_grid_column} onRowClick={onRowClickGridUser} style={{ marginBottom: 50 }} />
                </div>
            </div>
        </>
    )
}
