import { Button, ConfigProvider } from 'antd'
import { twMerge } from 'tailwind-merge'
import { colors } from '../../services/styles'

export function SL_Button({ type = 'primary', variant, className, children, ...rest }) {
    function getTokens() {
        let tokenVariant = {}
        switch (variant) {
            case 'secondary': {
                tokenVariant = {
                    colorPrimary: colors.secondary[600],
                }
                break
            }

            case 'close': {
                tokenVariant = {
                    colorPrimary: colors['sl-gray'][500],
                }
                break
            }

            default:
                tokenVariant = {
                    colorPrimary: colors.primary[900],
                }
        }

        let tokenType = {}
        switch (type) {
            case 'default': {
                tokenType = {
                    colorText: colors.primary[900],
                    colorBgContainerDisabled: colors['sl-gray'][200],
                    colorTextDisabled: colors['sl-gray'][500],
                    colorIcon: colors['sl-gray'][500],
                }
                break
            }

            case 'primary': {
                tokenType = {
                    colorBgContainerDisabled: colors['sl-gray'][200],
                    colorTextDisabled: colors['sl-gray'][500],
                    colorIcon: colors['sl-gray'][500],
                }
                break
            }

            case 'link': {
                tokenType = {
                    colorLink: colors.primary[700],
                    colorLinkActive: colors.primary[600],
                    colorLinkHover: colors.primary[600],
                }
                break
            }
        }

        return { ...tokenVariant, ...tokenType }
    }

    const classes = twMerge(`
        flex
        justify-center
        items-center
        ${className}
    `)

    return (
        <ConfigProvider
            theme={{
                token: getTokens(),
            }}
        >
            <Button type={type} {...rest} className={classes}>
                {children}
            </Button>
        </ConfigProvider>
    )
}
