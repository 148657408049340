import logo from '../../assets/logo.png'
import { ClipLoader } from 'react-spinners'
import { colors } from '../../services/styles'

export const SplashScreen = () => {
    return (
        <div className="w-screen h-screen gap-4 bg-primary-200 flex flex-col justify-center items-center">
            <div className="w-[250px] ss:w-[400px] aspect-equal bg-white rounded-full shadow-2xl flex justify-center">
                <img width={'100%'} height={'100%'} src={logo} className="object-contain p-4" />
            </div>

            <div className="flex flex-col gap-2 items-center mt-4">
                <ClipLoader color={colors.primary[800]} loading={true} size={30} />

                <span className="text-sm smb:text-base text-primary-800">
                    Desenvolvido por <span className="font-bold"> Solve Light</span>
                </span>
            </div>
        </div>
    )
}
