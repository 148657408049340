import { Button, Form, Input, InputNumber, Select, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ERegExp } from '../../../enums/ERegExp'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const { Option } = Select

export default function PersonDetail() {
    const [form] = Form.useForm()
    const { personId } = useParams()
    const [readOnly, setReadOnly] = useState(true)
    const [person, setPerson] = useState(null)

    const { setShowLoading, mobile_mode, windowHeight, showAlert } = useContext(GeneralContext)

    useEffect(() => {
        loadPersonInfo(personId)
    }, [])

    const loadPersonInfo = async (id) => {
        try {
            let person = await api.people.find(id)
            form.setFieldsValue(person.data)
            setPerson(person.data)
        } catch (error) {
            showAlert('INVALID_USER')
        }
    }
    const onFinishEdit = async (values) => {
        if (readOnly) {
            setReadOnly((current) => !current)
            return
        }

        setShowLoading(true)
        try {
            await api.people.update(personId, values)
            showAlert('USER_DATA_SAVED_SUCCESS', EAlertTypes.SUCCESS)
        } finally {
            setReadOnly((current) => !current)
            setShowLoading(false)
        }
    }

    const onCloseEdit = () => {
        setReadOnly(true)
        loadPersonInfo(personId)
    }

    const onOpenEdit = () => {
        readOnly ? setReadOnly(false) : form.submit()
    }

    return (
        <>
            <div className="font-bold text-lg text-appian-blue text-center" style={{ marginBottom: 10 }}>
                {displayMessage('USER_DETAIL')}
            </div>
            <div className="w-full flex-1">
                <Tabs
                    defaultActiveKey="1"
                    className="h-full"
                    tabBarStyle={{
                        display: 'flex',
                        height: '50px',
                    }}
                    items={[
                        {
                            label: displayMessage('PERSONAL_DATA'),
                            key: '1',
                            children: (
                                <>
                                    <Form onFinish={onFinishEdit} layout="vertical" requiredMark="optional" form={form}>
                                        <div
                                            style={{
                                                maxWidth: '100%',
                                                flexDirection: 'column',
                                                gap: 10,
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: mobile_mode ? 'column' : 'row',
                                                    gap: 8,
                                                }}
                                            >
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold">{displayMessage('FIRST_NAME')}:</span>}
                                                    name="name"
                                                    className={`w-full m-[0]`}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('FIRST_NAME_MANDATORY'),
                                                        },
                                                        {
                                                            pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                                            message: displayMessage('INVALID_INPUT_DATA'),
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="text" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>

                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold"> {displayMessage('REGISTRY_SUB')}:</span>}
                                                    name="registry_sub"
                                                    className={`w-full m-0`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: displayMessage('REGISTRY_SUB_MANDATORY'),
                                                        },
                                                        {
                                                            pattern: ERegExp.MUST_HAVE_LETTERS_OR_NUMBERS,
                                                            message: displayMessage('INVALID_INPUT_DATA'),
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                    style={{ flex: 1 }}
                                                >
                                                    <Input className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: mobile_mode ? 'column' : 'row',
                                                    gap: 8,
                                                }}
                                            >
                                                <Form.Item
                                                    label={<span className="text-fit-green-900 font-semibold"> {displayMessage('EMAIL')}:</span>}
                                                    name="email"
                                                    className={`w-full m-0`}
                                                    rules={[
                                                        { required: true, type: 'email' },
                                                        {
                                                            whitespace: false,
                                                            message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                                        },
                                                    ]}
                                                    style={{ flex: 2 }}
                                                >
                                                    <Input type="email" className="text-fit-green-900" disabled={readOnly} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            flexDirection: mobile_mode ? 'column' : 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: mobile_mode ? '100%' : 300,
                                                justifyContent: mobile_mode ? 'space-between' : 'flex-start',
                                                flexDirection: 'row',
                                                marginTop: mobile_mode ? 20 : 0,
                                            }}
                                        >
                                            <Button style={{ minWidth: 80, marginTop: 15 }} type={'primary'} onClick={onOpenEdit}>
                                                {readOnly ? displayMessage('EDIT') : displayMessage('SAVE_EDIT')}
                                            </Button>
                                            {!readOnly ? (
                                                <Button
                                                    style={{
                                                        minWidth: 80,
                                                        marginTop: 15,
                                                        marginLeft: mobile_mode ? 0 : 10,
                                                    }}
                                                    type={'primary'}
                                                    onClick={onCloseEdit}
                                                    danger
                                                >
                                                    {displayMessage('CLOSE')}
                                                </Button>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    )
}
