import { ClipLoader } from 'react-spinners'
import { useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { colors } from '../../services/styles'

export function Loader() {
    const { show_loading } = useContext(GeneralContext)

    return (
        show_loading && (
            <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                <ClipLoader color={colors.primary[700]} loading={true} size={50} />
            </div>
        )
    )
}
