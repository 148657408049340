import React, { useContext, useState } from 'react'
import { Form, ConfigProvider } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { colors } from '../../services/styles'
import { SL_Input } from '../../components/input/Input'
import { SL_Button } from '../../components/buttons/Button'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../enums/EAlertTypes'

export function ChangePassword(props) {
    const [newPassword, setNewPassword] = useState(null)
    const [confirmedNewPassword, setConfirmedNewPassword] = useState(null)
    const [formRef] = Form.useForm()

    const { showAlert } = useContext(GeneralContext)

    const onFinish = (values) => {
        if (!newPassword || !confirmedNewPassword) {
            showAlert('FILL_ALL_FIELDS_RESET_PASSWORD', EAlertTypes.WARNING)
            return
        }

        if (newPassword != confirmedNewPassword) {
            showAlert('PASSWORD_CONFIRMATION_FAILED', EAlertTypes.WARNING)
            return
        }

        const payload = { newPassword: values.new_password }

        api.auth.changePasswordForced(payload).then((rs) => {
            setTimeout(() => (window.location.href = '/dashboard'), 1500)
            showAlert('RESET_PASSWORD_SUCCESS', EAlertTypes.SUCCESS)
        })
    }

    return (
        <>
            <div className="font-bold mb-4 text-xl ss:text-2xl flex-col flex text-sl-gray-50 shadow-title text-center">{displayMessage('CHANGE_PASSWORD_TITLE')}</div>

            <Form name="reset_password" onFinish={onFinish} className={'w-full max-w-[400px]'} layout="vertical" form={formRef}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorError: colors['sl-red'][700],
                        },
                    }}
                >
                    <div className="flex gap-1 flex-col">
                        <div className="text-sl-gray-50 font-bold flex shadow-title">{displayMessage('NEW_PASSWORD_SHORT')}</div>

                        <Form.Item
                            name="new_password"
                            // className={`flex-1`}
                            rules={[{ required: true, message: displayMessage('PASSWORD_MANDATORY') }]}
                        >
                            <SL_Input
                                prefix={<LockOutlined className="" />}
                                type="password"
                                placeholder={displayMessage('NEW_PASSWORD_SHORT')}
                                autoComplete="do-not-use-auto-complete"
                                className="shadow-md"
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex gap-1 flex-col mt-1">
                        <div className="text-sl-gray-50 font-bold flex shadow-title">{displayMessage('CONFIRM_PASSWORD')}</div>

                        <Form.Item
                            name="confirm_password"
                            className={`flex-1`}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: displayMessage('DISPLAY_CONFIRM_PASSWORD'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(new Error(displayMessage('INVALID_CONFIRMED_PASSWORD')))
                                    },
                                }),
                            ]}
                        >
                            <div>
                                <SL_Input
                                    prefix={<LockOutlined />}
                                    type="password"
                                    className="shadow-md"
                                    value={confirmedNewPassword}
                                    placeholder={displayMessage('CONFIRM_PASSWORD')}
                                    onChange={(e) => {
                                        setConfirmedNewPassword(e.target.value)
                                    }}
                                />
                            </div>
                        </Form.Item>
                    </div>
                </ConfigProvider>

                <Form.Item hasFeedback style={{ marginBottom: 10, marginTop: 5 }}>
                    <SL_Button type="primary" className="w-full font-bold shadow-md" htmlType="submit">
                        {displayMessage('REDEFINE_PASSWORD')}
                    </SL_Button>
                </Form.Item>
            </Form>
        </>
    )
}
