import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import { formatFullTimeDate } from '../../../helpers/date'

export const access_keys_grid_column = [
    {
        field: 'name',
        headerName: displayMessage('NAME'),
        width: 250,
    },
    {
        field: 'is_active',
        headerName: displayMessage('STATUS'),
        width: 150,
        valueGetter: (params) => {
            return params?.row?.is_active
        },
        renderCell: (params) => {
            return params?.row?.is_active ? displayMessage('IS_ACTIVE_F') : displayMessage('IS_INACTIVE_F')
        },
    },
    {
        field: 'access_login',
        headerName: displayMessage('ACCESS_LOGIN'),
        width: 250,
    },
    {
        field: 'client_url_api',
        headerName: displayMessage('CLIENT_URL_API'),
        width: 250,
        renderCell: (params) => {
            return params?.row?.client_url_api ? params?.row?.client_url_api : displayMessage('NOT_INFORMED')
        },
    },
    {
        field: 'access_password',
        headerName: displayMessage('ACCESS_PASSWORD'),
        width: 350,
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATION_DATE'),
        width: 250,
        valueGetter: (params) => {
            return new Date(params?.row?.createdAt)
        },
        renderCell: (params) => {
            return formatFullTimeDate(params?.row?.createdAt)
        },
    },
    {
        field: 'creationUser',
        headerName: displayMessage('CREATION_BY'),
        width: 350,
        valueGetter: (params) => {
            return params?.row?.User?.full_name
        },
        renderCell: (params) => {
            return params?.row?.User?.full_name
        },
    },
    {
        field: 'activationDate',
        headerName: displayMessage('ACTIVATION_DATE'),
        width: 250,
        valueGetter: (params) => {
            return params?.row?.is_active
        },
        renderCell: (params) => {
            return params?.row?.is_active ? formatFullTimeDate(params?.row?.last_activation_date) : '-'
        },
    },
    {
        field: 'inactivationDate',
        headerName: displayMessage('INACTIVATION_DATE'),
        width: 250,
        valueGetter: (params) => {
            return params?.row?.is_active
        },
        renderCell: (params) => {
            return params?.row?.is_active ? '-' : formatFullTimeDate(params?.row?.last_inactivation_date)
        },
    },
    {
        field: 'is_xml_body_format',
        headerName: displayMessage('XML_FORMAT'),
        width: 250,
        valueGetter: ({ row }) => {
            return row?.is_xml_body_format
        },
        type: 'boolean',
    },
]
