import { useContext } from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import { DrawerMenu } from '../menus/DrawerMenu'
import { SideMenu } from '../menus/SideMenu'
import { Loader } from '../Loader'
import { TopMenu } from '../menus/TopMenu'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

const { Header, Content } = Layout

export const InternalLayout = () => {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <Layout className="bg-white flex flex-row">
            <Loader />

            {!mobile_mode && <SideMenu />}

            <Layout className="site-layout bg-white flex flex-col h-screen">
                <Header className="bg-white p-0 shadow-lg shadow-sl-gray-300 flex justify-end items-center px-4">
                    {mobile_mode && <DrawerMenu />}

                    {/* <NotificationDrawer /> */}
                    <TopMenu />
                </Header>

                <div className={`flex-1 overflow-scroll w-screen h-screen my-4 mx-0 smb:w-unset smb:h-unset smb:p-0 smb:m-0`}>
                    <Content className={`site-layout-background flex flex-col flex-1 h-full p-2 w-full smb:p-4 smb:w-unset`}>
                        <Outlet />
                    </Content>
                </div>
            </Layout>
        </Layout>
    )
}
