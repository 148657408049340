import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext/AuthContext'

export const PermissionedRoute = ({ routePermissions = [], children }) => {
    const { userPermissions } = useContext(AuthContext)

    const handleOnValidatePermissions = () => {
        return userPermissions?.some((userPermission) => routePermissions.find((routePermission) => routePermission?.code === userPermission?.code))
    }

    if (children && handleOnValidatePermissions()) {
        return children
    }

    return null
}
