import { displayMessage } from '../../../helpers/messages'

export const user_grid_column = [
    {
        field: 'id',
        headerName: displayMessage('CODE'),
        flex: 1,
    },
    {
        field: 'first_name',
        headerName: displayMessage('NAME'),
        valueGetter: (params) => {
            return params?.row?.full_name || ''
        },
        width: 250,
    },
    {
        field: 'email',
        headerName: displayMessage('EMAIL'),
        valueGetter: (params) => {
            return params?.row?.email || ''
        },
        flex: 2,
    },
    {
        field: 'type',
        headerName: displayMessage('TYPE'),
        valueGetter: (params) => {
            return params?.row.type_description || ''
        },
        flex: 1,
    },
]
