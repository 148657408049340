import React, { useContext } from 'react'
import { Input, Modal, Form, Select } from 'antd'
import api from '../../../services/api'
import { SL_Button } from '../../../components/buttons/Button'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ERegExp } from '../../../enums/ERegExp'
import { displayMessage } from '../../../helpers/messages'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const { Option } = Select

export default function CreateUser({ handleOnCloseModal, refreshUsers, eUserTypes }) {
    const [form] = Form.useForm()

    const { setShowLoading, mobile_mode, showAlert } = useContext(GeneralContext)

    var userTypeList = Object.values(eUserTypes)
        .filter((userType) => userType.code >= eUserTypes.ADMIN.code)
        .map((userType) => {
            return { value: userType.code, label: userType.description }
        })

    const handleCreateUser = async (values) => {
        setShowLoading(true)
        try {
            await api.user.create(values)
            await refreshUsers()
            await handleOnCloseModal(true)
            showAlert('CREATE_USER_SUCCESS', EAlertTypes.SUCCESS)
        } finally {
            setShowLoading(false)
        }
    }

    return (
        <Modal
            title={<div className="text-xl font-bold text-fit-green-900">{displayMessage('REGISTER_USER_2')}</div>}
            open={true}
            onCancel={() => handleOnCloseModal(false)}
            centered={true}
            footer={
                <div className="flex justify-end">
                    <SL_Button className="w-[150px] bg-gray-500 border-transparent" onClick={() => handleOnCloseModal(false)}>
                        {displayMessage('CLOSE')}
                    </SL_Button>

                    <SL_Button className="w-[150px]" onClick={() => form.submit()}>
                        {displayMessage('END_REGISTER')}
                    </SL_Button>
                </div>
            }
            width={750}
            bodyStyle={{ overflow: 'auto' }}
        >
            <Form onFinish={handleCreateUser} layout="vertical" requiredMark="optional" form={form}>
                <div style={{ display: 'flex', gap: 2, flexDirection: 'column', gap: 10 }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: mobile_mode ? 'column' : 'row',
                            gap: 10,
                        }}
                    >
                        <Form.Item
                            label={<span className="text-fit-green-900 font-semibold">{displayMessage('FIRST_NAME')}:</span>}
                            name="first_name"
                            className={`w-full m-0`}
                            rules={[
                                {
                                    required: true,
                                    message: displayMessage('FIRST_NAME_MANDATORY'),
                                    pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                    whitespace: false,
                                },
                            ]}
                            style={{ flex: 1 }}
                        >
                            <Input type="text" className="text-fit-green-900" />
                        </Form.Item>
                        <Form.Item
                            label={<span className="text-fit-green-900 font-semibold">{displayMessage('LAST_NAME')}:</span>}
                            name="last_name"
                            className={`w-full m-0`}
                            rules={[
                                {
                                    pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                    message: displayMessage('INVALID_INPUT_DATA'),
                                },
                                {
                                    whitespace: false,
                                    message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                },
                            ]}
                            style={{ flex: 1 }}
                        >
                            <Input type="text" className="text-fit-green-900" />
                        </Form.Item>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: mobile_mode ? 'column' : 'row',
                            gap: 10,
                        }}
                    >
                        <Form.Item
                            label={<span className="text-fit-green-900 font-semibold">{displayMessage('EMAIL')}:</span>}
                            name="email"
                            className={`w-full m-0`}
                            rules={[
                                {
                                    required: true,
                                    message: displayMessage('EMAIL_MANDATORY'),
                                    pattern: ERegExp.MUST_HAVE_LETTERS.regexp,
                                    whitespace: false,
                                },
                                {
                                    whitespace: false,
                                    message: displayMessage('NO_WHITE_SPACE_WARNING'),
                                },
                            ]}
                            style={{ flex: 1 }}
                        >
                            <Input type="text" className="text-fit-green-900" />
                        </Form.Item>
                        <Form.Item
                            label={<span className="text-fit-green-900 font-semibold">{displayMessage('USER_TYPE')}:</span>}
                            name="type"
                            className={`w-full m-0`}
                            rules={[{ required: true, message: displayMessage('USER_TYPE_MANDATORY') }]}
                            style={{ flex: 1 }}
                        >
                            <Select>
                                {userTypeList.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="font-bold">{displayMessage('DEFAULT_PASSWORD') + 'senha123'}</div>
                </div>
            </Form>
        </Modal>
    )
}
