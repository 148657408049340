import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { ScreenTitle } from '../../components/title/screenTitle'
import { SL_Button } from '../../components/buttons/Button'
import { displayMessage } from '../../helpers/messages'
import { client_grid_column } from '../client/grid_columns/client_grid_column'
import api from '../../services/api'
import { BankOutlined, CloseOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import ClientFilters from '../client/components/ClientFilters'
import { useNavigate } from 'react-router-dom'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { colors } from '../../services/styles'

export function ListClients() {
    const navigate = useNavigate()
    const [clients, setClients] = useState([])
    const [isGridLoading, setIsGridLoading] = useState(true)
    const [isFiltersVisible, setIsFilterVisible] = useState(false)
    const [currentClient, setCurrentClient] = useState(null)

    const gridRef = useGridApiRef()

    const { showAlert, mobile_mode } = useContext(GeneralContext)

    useEffect(() => {
        loadClientList()
    }, [])

    async function loadClientList() {
        try {
            const { data } = await api.client.list()
            setClients(data ?? [])
            gridRef.current.setSelectionModel([])
        } catch (error) {
            showAlert('LOAD_CLIENTS_ERROR')
        } finally {
            setIsGridLoading(false)
        }
    }
    async function loadFilterClientList(values) {
        try {
            if (values.by_company != null) {
                values.by_company = values.by_company.map((client) => {
                    return client.label
                })
            }
            const { data } = await api.client.list(values)
            setClients(data ?? [])
            gridRef.current.setSelectionModel([])
        } catch (error) {
            showAlert('LOAD_CLIENTS_ERROR')
        } finally {
            setIsGridLoading(false)
        }
    }

    const handleRowChange = (e) => {
        setCurrentClient(e?.row)
    }

    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    const handleOnGoToDetailClient = () => {
        navigate('' + currentClient.id)
    }

    return (
        <>
            <div className="flex flex-col flex-1 h-full">
                <div className={`flex w-full bg-fit-green-700 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col gap-4' : 'gap-2'}`}>
                    <div className={`flex-1 flex flex-wrap gap-2 rounded-t-md p-2`} style={{ background: colors.primary[900] }}>
                        <div className={'flex self-center'}>
                            <div className="self-center text-white font-semibold text-base">{displayMessage('CLIENTS_LIST')}</div>
                        </div>
                        <div className={'flex flex-1 justify-end gap-2'}>
                            <SL_Button
                                type="default"
                                className={`flex items-center flex-1 ${mobile_mode ? 'w-[200px]' : 'max-w-[200px]'}`}
                                onClick={handleOnGoToDetailClient}
                                disabled={!currentClient || currentClient == null}
                                icon={<EditOutlined className="text-base flex items-center" />}
                            >
                                <span className="text-fit-green-800 font-semibold flex-1">{displayMessage('DETAIL_CLIENT')}</span>
                            </SL_Button>

                            <Button
                                type="default"
                                shape="circle"
                                onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                                icon={
                                    !isFiltersVisible ? (
                                        <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    ) : (
                                        <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                    )
                                }
                            ></Button>
                        </div>
                    </div>
                </div>
                {isFiltersVisible ? <ClientFilters handleOnSearchClients={loadFilterClientList} filterHeight={'20vh'} /> : <div />}
                <div className={`w-full flex-1 min-h-[400px] smb:min-h-0 mb-[20px]`}>
                    <SL_Grid rows={clients} columns={client_grid_column} loading={isGridLoading} onRowClick={handleRowChange} apiRef={gridRef} />
                </div>
            </div>
        </>
    )
}
