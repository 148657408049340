function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook()

    this.SheetNames = []

    this.Sheets = {}
}

const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff

    return buf
}

function printExamsTotalizers(exams_formatter, XLSX, ws) {
    for (let i = 0; i < exams_formatter.length; i++) {
        const group = exams_formatter[i]
        XLSX.utils.sheet_add_aoa(ws, [[group?.title]], { origin: -1 })
        for (let j = 0; j < group?.subgroups.length; j++) {
            const subgroup = group?.subgroups[j]
            XLSX.utils.sheet_add_aoa(ws, [''], { origin: -1 })
            const exams = subgroup?.exams.map((exam) => exam?.description)
            const exams_count = subgroup?.exams.map((exam) => exam?.count)
            XLSX.utils.sheet_add_aoa(ws, [exams], { origin: -1 })
            XLSX.utils.sheet_add_aoa(ws, [exams_count], { origin: -1 })
            XLSX.utils.sheet_add_aoa(ws, [''], { origin: -1 })
        }
    }
}

export default (data, rowLabels, eventCount, exams_formatter, name = 'import.xlsx') => {
    import('xlsx').then((XLSX) => {
        const wb = new Workbook()
        const ws = XLSX.utils.json_to_sheet(data)
        wb.SheetNames.push('export')
        wb.Sheets['export'] = ws

        XLSX.utils.sheet_add_aoa(ws, [''], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowLabels], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [eventCount], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [''], { origin: -1 })

        printExamsTotalizers(exams_formatter, XLSX, ws)

        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'binary',
        })

        let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }))

        download(url, name)
    })
}
