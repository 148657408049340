import { Select, Spin } from 'antd'
import axios from 'axios'
import { useRef, useState } from 'react'

export function SL_AutoCompleteSelect({ fetchOptions, debounceTimeout = 250, charactersLimitToFetch = 3, value, onChange, placeholder, ...props }) {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const fetchRef = useRef(0) //Tratativa pega da doc do Antd

    function cleanOptions() {
        if (options.length != 0) {
            setOptions([])
        }
    }

    const onSearch = (value) => {
        cleanOptions()
        if (value.length < charactersLimitToFetch) {
            fetchRef.current = 0
            return
        }

        setFetching(true)

        fetchRef.current += 1
        const fetchId = fetchRef.current

        setTimeout(async () => {
            try {
                const newOptions = await fetchOptions(value)
                if (fetchId !== fetchRef.current) {
                    return
                }

                setOptions(newOptions)
            } finally {
                setFetching(false)
            }
        }, debounceTimeout)
    }

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={onSearch}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onBlur={() => {
                cleanOptions()
            }}
            mode="multiple"
            style={{
                width: '200px',
            }}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            {...props}
        />
    )
}

//EXEMPLO DE USO
// const Test = () => {
//     const [value, setValue] = useState([])

//     async function fetchUserList(value) {
//         const { data } = await axios.get('https://randomuser.me/api/?results=5')

//         return data.results.map(user => ({
//             label: `${user.name.first} ${user.name.last}`,
//             value: user.login.username,
//         }))
//     }

//     return (
//         <MultiSelect
//             value={value}
//             onChange={newValue => {
//                 setValue(newValue)
//             }}
//             placeholder="Select users"
//             fetchOptions={fetchUserList}
//         />
//     )
// }
