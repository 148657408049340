import { Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { DateTime } from 'luxon'
import { EEventTypes } from '../../../enums/EEventTypes'
import { formatCpf } from '../../../helpers/helper'

const dateFormat = 'dd/LL/yyyy HH:mm'
export const event_grid_column = [
    {
        field: 'personal_id',
        headerName: displayMessage('CPF'),
        width: 120,
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id)
            if (row.personal_id) return formatCpf(row.personal_id)
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'acquisition_id',
        headerName: displayMessage('ID'),
        width: 70,
    },
    {
        field: 'id',
        headerName: displayMessage('CODE'),
        width: 70,
    },
    {
        field: 'event_type',
        headerName: displayMessage('EVENT_TYPE'),
        width: 180,
        valueGetter: (params) => {
            return params?.row?.type_description || ''
        },
    },
    {
        field: 'company_name',
        headerName: displayMessage('COMPANY'),
        width: 150,
        valueGetter: (params) => {
            return params?.row?.Person?.Client?.company_name || ''
        },
    },
    {
        field: 'linked_user',
        headerName: displayMessage('LINKED_USER'),
        width: 150,
        valueGetter: (params) => {
            return params?.row?.Person?.name || params?.row?.Person?.email || ''
        },
        width: 200,
    },
    {
        field: 'event_date',
        headerName: displayMessage('DATE'),
        width: 150,
        renderCell: (params) => {
            return DateTime.fromISO(params?.row?.event_date).setZone('UTC+0').toFormat(dateFormat) || ''
        },
        valueGetter: (params) => {
            return DateTime.fromISO(params?.row?.event_date).ts
        },
    },
    {
        field: 'vinculated_exams_quantity',
        headerName: displayMessage('VINCULATED_EXAMS_QUANTITY'),
        width: 250,
        renderCell: (params) => {
            const { exams } = params.row
            return exams?.length > 0 ? exams.length : displayMessage('NO_VINCULATED_EXAMS')
        },
    },
    {
        field: 'exams',
        headerName: displayMessage('VINCULATED_EXAMS'),
        width: 450,
        renderCell: (params) => {
            const { exams, type } = params.row

            const eventTypesWithSampleCollectionRequired = [EEventTypes.CONSULTAR_RESULTADOS.code, EEventTypes.CADASTRO_COLETA.code]

            return (
                <div>
                    <Tooltip placement="top" title={formatExams(exams, true)}>
                        {formatExams(exams)}
                    </Tooltip>
                </div>
            )
        },
    },
]

function formatExams(exams, all = false) {
    let examsToShow = ''
    const limitExams = all ? exams.length : exams.length > 5 ? 5 : exams.length
    for (let i = 0; i < limitExams; i++) {
        if (!exams[i + 1]) {
            examsToShow += `${exams[i]?.description ?? ''}`
        } else if (exams[i + 1] && i + 1 === limitExams) {
            examsToShow += `${exams[i]?.description ?? ''}... `
        } else {
            examsToShow += `${exams[i]?.description ?? ''}, `
        }
    }

    return examsToShow
}
