import { UserOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useContext } from 'react'
import { displayMessage } from '../../../helpers/messages'
import menus from './options/Menus'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom'

export function TopMenu() {
    const { setLoggedUser, loggedUser } = useContext(AuthContext)
    const navigate = useNavigate()

    const handleOnClickLogout = () => {
        setLoggedUser(null)
    }

    const handleOnNavigate = (link) => {
        navigate(link)
    }

    function createTopMenuItem(item) {
        return {
            key: item.key,
            label: item.title,
            style: { width: '100%' },
            onClick: () => handleOnNavigate(item.key),
        }
    }

    const items = [
        ...menus.topMenuItens.map((item) => createTopMenuItem(item)),

        {
            key: '/logout',
            label: displayMessage('LOGOUT'),
            onClick: handleOnClickLogout,
            style: { width: '100%' },
        },
    ]

    return (
        <div className="flex items-center">
            <Dropdown menu={{ items }} placement="bottomLeft" size="large">
                <Button icon={<UserOutlined className="text-xl text-primary-900 flex items-center" />} className="flex items-center justify-center min-h-[40px] text-base text-primary-900">
                    {loggedUser?.full_name}
                </Button>
            </Dropdown>
        </div>
    )
}
