import { axiosInstance } from './axiosInstance'

const path = {
    enums: '/api/enums',
    auth: '/api/auth-users',
    users: '/api/users',
    client: '/api/clients',
    attachments: '/api/attachments',
    notifications: '/api/notifications',
    events: '/api/events',
    people: '/api/people',
    medicalExams: '/api/medical-exams',
    clientAccessKeys: '/api/client-access-keys',
}

export default {
    enums: {
        list: () => {
            return axiosInstance.get(`${path.enums}/`)
        },
        find: (name) => {
            return axiosInstance.get(`${path.enums}/${name}`)
        },
    },

    auth: {
        authenticate: (body) => {
            return axiosInstance.post(`${path.auth}/authenticate-panel`, body)
        },
        forgotPassword: (email) => {
            return axiosInstance.post(`${path.auth}/reset-password/${email}`)
        },
        changePassword: (id, body) => {
            return axiosInstance.put(`${path.auth}/change-password/${id}`, body)
        },
        changePasswordForced: (body) => {
            return axiosInstance.put(`${path.auth}/change-password-forced/`, body)
        },
        resetPasswordForced: (body, token) => {
            return axiosInstance.put(`${path.auth}/change-password-forced/`, body, {
                headers: { common: { ['Authorization']: `Bearer ${token}` } },
            })
        },
        currentUser: () => {
            return axiosInstance.get(`${path.auth}/current-user`)
        },
    },

    user: {
        find: (id) => {
            return axiosInstance.get(`${path.users}/${id}`)
        },
        list: (params) => {
            return axiosInstance.get(`${path.users}/`, { params })
        },
        create: (body) => {
            return axiosInstance.post(`${path.users}`, body)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.users}/${id}`, body)
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.users}/${id}`)
        },
        findByClient: (id, params) => {
            return axiosInstance.get(`${path.users}/by-client/${id}`, { params })
        },
    },

    client: {
        update: (id, body) => {
            return axiosInstance.put(`${path.client}/${id}`, body)
        },
        create: (body) => {
            return axiosInstance.post(`${path.client}`, body)
        },
        listClients: (params) => {
            return axiosInstance.get(`${path.client}/list/${params}`)
        },
        get: (userId) => {
            return axiosInstance.get(`${path.client}/${userId}`)
        },
        list: (params) => {
            return axiosInstance.get(`${path.client}/`, { params })
        },
        find: (id, params) => {
            return axiosInstance.get(`${path.client}/${id}`, { params })
        },
    },

    notifications: {
        listAll: (params) => {
            return axiosInstance.get(`${path.notifications}/`, { params })
        },
        find: (id) => {
            return axiosInstance.get(`${path.notifications}/${id}`)
        },
        create: (body) => {
            return axiosInstance.post(`${path.notifications}/`, body)
        },
        markAllAsRead: () => {
            return axiosInstance.put(`${path.notifications}/read`)
        },
        markOneAsRead: (id) => {
            return axiosInstance.put(`${path.notifications}/read/${id}`)
        },
    },

    events: {
        list: (params) => {
            return axiosInstance.get(`${path.events}/`, { params })
        },
    },

    people: {
        find: (id) => {
            return axiosInstance.get(`${path.people}/${id}`)
        },
        list: (params) => {
            return axiosInstance.get(`${path.people}/`, { params })
        },
        create: (body) => {
            return axiosInstance.post(`${path.people}`, body)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.people}/${id}`, body)
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.people}/${id}`)
        },
        findByClient: (id, params) => {
            return axiosInstance.get(`${path.people}/by-client/${id}`, { params })
        },
    },

    medicalExams: {
        list: (params) => {
            return axiosInstance.get(`${path.medicalExams}/`, params)
        },
        listSerialized: (params) => {
            return axiosInstance.get(`${path.medicalExams}/serialized`, params)
        },
        find: (id) => {
            return axiosInstance.get(`${path.medicalExams}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.medicalExams}/${id}`, body)
        },
       
    },

    clientAccessKeys: {
        listByClient: (clientId) => {
            return axiosInstance.get(`${path.clientAccessKeys}/${clientId}`)
        },
        create: (body) => {
            return axiosInstance.post(`${path.clientAccessKeys}/`, body)
        },
        update: (body, id) => {
            return axiosInstance.put(`${path.clientAccessKeys}/${id}`, body)
        },
        activate: (id) => {
            return axiosInstance.put(`${path.clientAccessKeys}/activate/${id}`)
        },
        inactivate: (id) => {
            return axiosInstance.put(`${path.clientAccessKeys}/inactivate/${id}`)
        },
    },
}
