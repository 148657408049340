import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { SL_Button } from '../../components/buttons/Button'
import { displayMessage } from '../../helpers/messages'
import { event_grid_column } from './grid_columns/event_grid_column'
import api from '../../services/api'
import { CloseOutlined, FilterOutlined, DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import EventFilters from './components/EventFilters'
import { DateTime } from 'luxon'
import { colors } from '../../services/styles'
import { Totalizer } from '../../components/grid/Totalizer'
import exportToExcel from '../../services/export'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { ExamTotalizer } from '../../components/grid/ExamTotalizer'
import { formatCpf } from '../../helpers/helper'

export function ListEvents() {
    const [events, setEvents] = useState([])
    const [isGridLoading, setIsGridLoading] = useState(true)
    const [isFiltersVisible, setIsFilterVisible] = useState(false)
    const [clients, setClients] = useState([])
    const [eventCount, setEventCount] = useState([])
    const [exams, setExams] = useState([])
    const [examsTotalizers, setExamsTotalizers] = useState([])
    const [labels, setLabels] = useState([])
    const [isTotalizerVisible, setIsTotalizerVisible] = useState(false)
    const [isExamsTotalizerVisible, setIsExamsTotalizerVisible] = useState(false)

    const dateFormat = 'dd-LL-yyyy'
    const formattedDateTime = DateTime.now().startOf('day').toFormat(dateFormat)
    const [eEventTypes, setEEventTypes] = useState([])

    const { showAlert, mobile_mode } = useContext(GeneralContext)
    const apiRef = useGridApiRef()

    const today = DateTime.now().endOf('day').toJSDate()
    const monthAgo = DateTime.now().minus({ month: 1 }).endOf('day').toJSDate()

    useEffect(() => {
        loadDataControl({
            start_date: DateTime.fromJSDate(monthAgo).toFormat(dateFormat),
            end_date: DateTime.fromJSDate(today).toFormat(dateFormat),
        })
        loadClientList()
        loadMedicalExams()
    }, [])

    async function loadLabelsAndCounters(data) {
        setExamsTotalizers(data.examCounters.groups)
        setEvents(data.events ?? [])
        setEventCount(data.eventsByType ?? [])
        setLabels(data.labels)
    }

    const loadDataControl = async (currentFilters) => {
        try {
            const { data: enumEventTypes } = await api.enums.find('EEventTypes')
            setEEventTypes(enumEventTypes)

            const filters = { ...currentFilters }
            const { data } = await api.events.list(filters)
            loadLabelsAndCounters(data)
        } finally {
            setIsGridLoading(false)
        }
    }

    async function loadEventFilterList(values) {
        setEvents([])
        setIsGridLoading(true)
        try {
            if (values.by_client != null) {
                values.by_client = values.by_client.map((client) => {
                    return client.label
                })
            }
            values.start_date = values.start_date ? DateTime.fromJSDate(values.start_date.$d).toFormat('dd-MM-yyyy') : null
            values.end_date = values.end_date ? DateTime.fromJSDate(values.end_date.$d).toFormat('dd-MM-yyyy') : null

            const { data } = await api.events.list(values)
            loadLabelsAndCounters(data)
        } finally {
            setIsGridLoading(false)
        }
    }

    async function loadClientList() {
        try {
            const { data } = await api.client.list()
            setClients(data)
        } catch (error) {
            showAlert('LOAD_CLIENTS_ERROR')
        }
    }

    async function loadMedicalExams() {
        try {
            const { data } = await api.medicalExams.list()
            setExams(
                data.map((exam) => {
                    return {
                        value: exam.code,
                        label: exam.description,
                    }
                })
            )
        } catch (error) {
            showAlert('LOAD_EXAMS_ERROR')
        }
    }

    function handleOnCloseFilters() {
        setIsFilterVisible(false)
    }

    function handleOnOpenFilters() {
        setIsFilterVisible(true)
    }

    function formatExamsToExport(examTotalizers) {
        examTotalizers.forEach((group) => {
            group.title = displayMessage('GROUP_TITLE', [group?.description])
        })

        return examTotalizers
    }

    function handleOnExportExcel() {
        const gridRef = apiRef.current
        gridRef.setSelectionModel([])
        let rowsMap = gridRef.getVisibleRowModels()
        let visibleColumns = gridRef.getVisibleColumns()
        let rowsList = []

        rowsMap.forEach((row) => {
            let obj = {}
            visibleColumns.forEach((column) => {
                switch (column.field) {
                    case 'event_type':
                        obj[column.headerName] = row.type_description
                        break
                    case 'company_name':
                        obj[column.headerName] = row.Person.Client.company_name
                        break
                    case 'linked_user':
                        obj[column.headerName] = row.Person.name
                        break
                    case 'event_date':
                        obj[column.headerName] = DateTime.fromISO(row.event_date).startOf('day').toFormat(dateFormat)
                        break
                    case 'vinculated_exams_quantity':
                        obj[column.headerName] = row.exams.length || displayMessage('NO_VINCULATED_EXAMS')
                        break
                    case 'exams':
                        obj[column.headerName] = row.exams.reduce((accumulator, currentValue, index) => accumulator + currentValue.description + (index === row.exams.length - 1 ? '' : ', '), '')
                        break
                    case 'personal_id':
                        obj[column.headerName] = formatCpf(row?.personal_id)
                        break
                    default:
                        obj[column.headerName] = row[column.field]
                }
            })
            rowsList.push(obj)
        })

        const exams_formatter = formatExamsToExport(examsTotalizers)

        exportToExcel(rowsList, labels, eventCount, exams_formatter, `eventList_${formattedDateTime}.xlsx`)
    }

    return (
        <>
            <div className={`h-full`}>
                <div className={`flex w-full bg-fit-green-700 rounded-tl-[4px] rounded-tr-[4px] flex-wrap ${mobile_mode ? 'flex-col gap-4' : 'gap-2'}`}>
                    <div className={`flex-1 flex flex-wrap gap-2 rounded-t-md p-2`} style={{ background: colors.primary[900] }}>
                        <div className={'flex self-center'}>
                            <div className="self-center text-white font-semibold text-base">{displayMessage('EVENT_LIST')}</div>
                        </div>
                        <div className={'flex flex-1 justify-end gap-2'}>
                            <Tooltip placement="top" title={isFiltersVisible ? displayMessage('CLOSE_FILTERS') : displayMessage('OPEN_FILTERS')}>
                                <SL_Button
                                    type="default"
                                    shape="circle"
                                    onClick={isFiltersVisible ? handleOnCloseFilters : handleOnOpenFilters}
                                    icon={
                                        !isFiltersVisible ? (
                                            <FilterOutlined className="text-fit-green-800 flex items-center justify-center" />
                                        ) : (
                                            <CloseOutlined className="text-fit-green-800 flex items-center justify-center" />
                                        )
                                    }
                                />
                            </Tooltip>

                            <Tooltip placement="top" title={displayMessage('EXPORT')}>
                                <SL_Button
                                    type="default"
                                    shape="circle"
                                    onClick={() => handleOnExportExcel()}
                                    icon={<DownloadOutlined className="text-fit-green-800 flex items-center justify-center" />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {isFiltersVisible ? (
                    <EventFilters handleOnSearchClients={loadEventFilterList} enumEventTypes={eEventTypes} examOptions={exams} today={today} monthAgo={monthAgo} filterHeight={'20vh'} />
                ) : (
                    <div />
                )}
                <div className={`w-full h-[600px] ${isFiltersVisible ? '' : 'flex-1 smb:min-h-0'} mb-[20px]`}>
                    <SL_Grid apiRef={apiRef} rows={events} columns={event_grid_column} loading={isGridLoading} className={isFiltersVisible ? 'min-h-[400px]' : ''} />
                </div>

                <div className="flex flex-col mt-1">
                    <div className={`flex flex-1 p-2 justify-between bg-blue gap-1 flex-row bg-primary-900`}>
                        <span className="self-center text-white flex-1 text-center font-medium text-lg">{displayMessage('TOTALIZERS')}</span>
                        <SL_Button
                            style={{
                                background: 'white',
                                borderColor: 'darkblue',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                            className="items-center w-30 flex"
                            onClick={() => {
                                isTotalizerVisible ? setIsTotalizerVisible(false) : setIsTotalizerVisible(true)
                            }}
                            shape="circle"
                            icon={isTotalizerVisible ? <UpOutlined /> : <DownOutlined />}
                        />
                    </div>
                </div>

                {isTotalizerVisible ? <Totalizer eventCount={eventCount} labels={labels} messageTotal={displayMessage('TOTAL_EVENTS')}></Totalizer> : <div />}

                <div className="flex flex-col mt-1">
                    <div className={`flex flex-1 p-2 justify-between bg-blue gap-1 flex-row bg-primary-900`}>
                        <span className="self-center text-white flex-1 text-center font-medium text-lg">{displayMessage('EXAM_TOTALIZERS')}</span>
                        <SL_Button
                            style={{
                                background: 'white',
                                borderColor: 'darkblue',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                            className="items-center w-30 flex"
                            onClick={() => {
                                setIsExamsTotalizerVisible((current) => !current)
                            }}
                            shape="circle"
                            icon={isExamsTotalizerVisible ? <UpOutlined /> : <DownOutlined />}
                        />
                    </div>
                </div>

                {isExamsTotalizerVisible ? <ExamTotalizer totalizers={examsTotalizers}></ExamTotalizer> : <div />}
            </div>
        </>
    )
}
