import { useState } from 'react'
import { axiosInstance } from '../../services/axiosInstance'
import { AuthContext } from './AuthContext'

export function AuthContextProvider({ children }) {
    const [loggedUser, setLoggedUser] = useState(null)
    const [userPermissions, setUserPermissions] = useState([])

    function onChangeLoggedUser(user) {
        if (!user) {
            removeAuthorizationToken()

            const redirectRoute = window.location.origin + '/login'
            window.location.href = redirectRoute

            setTimeout(() => {
                setLoggedUser(null)
            }, 2000)

            return
        }
        setLoggedUser(user)
        onSetUserPermissions(user)
    }

    function onSetUserPermissions(user) {
        const userPermission = { code: user.type }
        setUserPermissions([userPermission])
    }

    function setAuthorizationToken(token, shouldFormatToken = false) {
        let formattedToken = token

        if (shouldFormatToken) {
            formattedToken = 'Bearer ' + formattedToken
        }

        axiosInstance.defaults.headers.common.Authorization = formattedToken
        localStorage.setItem('token', formattedToken)
    }

    function getAuthorizationToken() {
        return localStorage.getItem('token')
    }

    function removeAuthorizationToken() {
        axiosInstance.defaults.headers.common.Authorization = null

        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    const isAdmin = loggedUser?.type == 0 ?? false

    return (
        <AuthContext.Provider
            value={{
                loggedUser,
                setLoggedUser: onChangeLoggedUser,
                userPermissions,
                setUserPermissions,
                isAdmin,
                setAuthorizationToken,
                getAuthorizationToken,
                removeAuthorizationToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
